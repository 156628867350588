import React, { useCallback } from "react";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import Feature from "ol/Feature";
import { RLayerVector, RFeature, RStyle, RPopup } from "rlayers";
import { Layer } from "../../config/interfaces";
import mountains from "../../icons/mountains.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import styles from "../../Map.module.css";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function PGUMountainsLayer(layers: AtlasLayerInterface) {
  // Берем clusterId и  map_config из стора:
  const { clusterId, map_config } = useSelector(
    (state: RootState) => state.map
  );

  const layer = layers.layers[0];
  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });
  type inputDataType = {
    records: { fields: { name: string, height: number, color_hex: string } }[];
  };
  const fetchData = fetch(layer_url).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ records: [] } as inputDataType);
  const [current, setCurrent] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);

  const getIcon = (f: any) => {
    let color = f.values_.color_hex.trim();
    return (<RStyle.RIcon src={mountains} color={color}/>);   
  }

  const flyTo = (e: any) => {
    const [xMin, yMin, xMax, yMax] = e.target.getGeometry().getExtent();
    const newExt = [xMin + xMin * 0.002, yMin, xMax + xMax * 0.002, yMax];
    e.map.getView().fit(newExt, {
      duration: 750,
      maxZoom: 11,
    })
  }

  return (
    <>
      <RLayerVector
        zIndex={1}
        format={parser}
        url={layer_url}
        onPointerEnter={useCallback((e: any) => setCurrent(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current === e.target && setCurrent(null),
          [current]
        )}
        onClick={flyTo}
      >
        <RStyle.RStyle
          render={useCallback(
            (f: any) => (getIcon(f)),
            [data]
          )}
        />
      </RLayerVector>
      { layer.label ? 
      <RLayerVector zIndex={3}>
        {current ? (
          <div>
            <RFeature geometry={current.getGeometry()}>
              <RPopup trigger={"hover"} className={styles.customPopup} positioning='center-left'>
                <div className={styles.plashkaMountains}>
                    <div className={styles.mountainName}>{current.get('name')}</div>
                    <div className={styles.mountainH}>{current.get('height')}</div>
                </div>
              </RPopup>
            </RFeature>
          </div>
        ) : null}
      </RLayerVector> : null }
    </>
  );
}
