import React, { useCallback } from "react";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import Feature from "ol/Feature";
import { RLayerVector, RFeature, ROverlay } from "rlayers";
import { Layer } from "../../config/interfaces";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import { SimpleRectangle, SimpleRectangleSelectedLabel } from "../../elements/diagrams";
import { useSelector} from "react-redux";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function ZapovednikDiagramms(layers: AtlasLayerInterface) {
  // layer1
  const layer = layers.layers[0];
  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });
  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { name: string, square: string, color_hex: string } }[];
  };
  const fetchData = fetch(layer_url).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ features: [] } as inputDataType);
  const [current, setCurrent] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);

  const [features, setFeatures] = React.useState(() =>
    Object.keys(data.features).map(
      (f) =>
        new Feature({
          geometry: new Point(fromLonLat([44.2558, 46.3078])),
          name: f,
        })
    )
  );

  const sort_data: any[] = [];
  data.features.map((f) => (sort_data.push(f)));
  sort_data.sort((a, b) => Number(a.properties.square.replace(',', '.')) - Number(b.properties.square.replace(',', '.')));
  
  const isSelected = useSelector((state: any) => state.selectFeature.isSelected);
  const selectedFeature = useSelector((state: any) => state.selectFeature.feature);

  const value2number = (value: string | number) => {
    if (typeof value === 'string') {
      return Number(value.replace(',', '.'))
    }
    return value
  }

  return (
    <>
      <RLayerVector
        zIndex={2}
        visible={false}
      >
        {isSelected ?
            <RFeature geometry={
              new Point(fromLonLat([
                selectedFeature.geometry.coordinates[0], 
                selectedFeature.geometry.coordinates[1]
                ]))}>
            <ROverlay 
            positioning="top-left">
              <SimpleRectangleSelectedLabel 
                value={value2number(selectedFeature.properties.square)} 
                width={value2number(selectedFeature.properties.square)/3} height={value2number(selectedFeature.properties.square)/3}  
                color={'#ffffff'} 
                background={selectedFeature.properties.color_hex}
                minValue={54}
                label={selectedFeature.properties.name}/>
           </ROverlay>
          </RFeature>
          : 
          <RFeature geometry={new Point(fromLonLat([0, 0]))}>
            <ROverlay positioning="center-left"></ROverlay>
          </RFeature>
           }
        {sort_data.map((f) => (
          <RFeature 
          key={f.id}
          geometry={new Point(fromLonLat(f.geometry.coordinates))}
          properties={f.properties}
          >
          <ROverlay 
          positioning="top-left">
              <SimpleRectangle 
                feature={f}
                value={value2number(f.properties.square)} 
                width={value2number(f.properties.square)/3} height={value2number(f.properties.square)/3} 
                color={'#ffffff'} 
                background={f.properties.color_hex}
                minValue={105}
                label={f.properties.name}/>
           </ROverlay>
          </RFeature>
        ))}
      </RLayerVector>
    </>
  );
}
