import React from "react";
import styles from "./Checkbox.module.scss";

export type CheckboxProps = React.AllHTMLAttributes<HTMLInputElement> & {
  checked: boolean;
  setChecked: (val: boolean) => void;
};

export default function Checkbox(props: CheckboxProps) {
  return (
    <label style={{ ...props.style }} className={styles.container}>
      <input
        type="checkbox"
        checked={props.checked}
        onChange={(event) => props.setChecked(event.target.checked)}
      />
      <span className={styles.checkmark}></span>
      {props.label}
    </label>
  );
}
