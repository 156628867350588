import React from "react";
import styles from "./NewsWindow.module.scss";

export type NewsWindowProps = {};

const NewsWindow: React.FC<NewsWindowProps> = () => {
  return (
    <div className={styles.container}>
      <div className={styles.mainTitle}>НОВОСТИ</div>
      <div className={styles.newsContainer}>
        <div className={styles.newsTitleContainer}>
          <div className={styles.newsDate}>10.12.2023</div>
          <div className={styles.newsTitle}>
            Стратегическое развитие: российские ВУЗы объединили усилия
            в создании инновационного «Туристского атласа»
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.paragraph}>
            Российские вузы в рамках консорциума МГИМО госпрограммы «Приоритет
            2030» разработали информационно-аналитическую систему «Туристский
            атлас. Путеводитель инвестора и руководителя». Релиз проекта
            опубликован в сети 10 декабря 2023 года.
          </div>
          <div className={styles.paragraph}>
            В консорциум вошли Южный федеральный университет, Новосибирский
            государственный университет экономики и управления, Российский
            государственный гидрометеорологический университет, Оренбургский
            государственный университет, Ставропольский государственный аграрный
            университет, Пятигорский государственный университет, Калмыцкий
            государственный университет, Владимирский институт туризма и
            гостеприимства.
          </div>
          <div className={styles.paragraph}>
            «Туристский атлас создается с учетом потребностей государственных
            структур и бизнеса, чтобы обеспечить актуальные данные и инструменты
            анализа для эффективного развития туризма. Главной целью является
            предоставление всестороннего информационного среза, а также
            возможности для стратегического планирования и мониторинга в целях
            улучшения условий отдыха и развития инфраструктуры», – рассказал
            Артем Владимирович Мальгин, проректор по развитию МГИМО, профессор
            кафедры Международных отношений и внешней политики России.
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsWindow;
