import { memo, useState } from "react";
import styles from "./LayerElement.module.scss";
import Checkbox from "../Checkbox/Checkbox";
import ButtonPlusMinus from "../ButtonPlusMinus/ButtonPlusMinus";
import { ContentBlockByType } from "../DataBlocks/DataBlocks";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  removeAbstractLayerFromWorkSpace,
  toggleMultipleLayersVisible,
} from "../../redux/map";
import { AbstractLayer } from "../../pages/ConstructorPage";
import classNames from "classnames";
import { SortableKnob } from "react-easy-sort";

function getLayerIds(layer: AbstractLayer) {
  return layer.map_layers.reduce((acc: string[], cur) => {
    acc.push(cur.layer);
    return acc;
  }, []);
}

export default memo(function LayerElement({
  layer,
  index,
}: {
  layer: AbstractLayer;
  index: number;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const layerIds = getLayerIds(layer);

  const layers = useSelector(
    (state: RootState) => state.map.map_config?.layers
  );

  const checked =
    layers
      ?.filter((layer) => layerIds.includes(layer.layer))
      .every((layer) => layer.visible === true) ?? false;

  const handleOnContextMenuClick = () => {
    dispatch(removeAbstractLayerFromWorkSpace(index));
  };

  const handleMouseDown = () => {
    setIsDragging(true);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <div
      className={styles.elemItem}
      onContextMenu={
        handleOnContextMenuClick
          ? (e) => {
              e.preventDefault();
              handleOnContextMenuClick();
            }
          : undefined
      }
    >
      <div
        className={classNames(styles.elementContainer, {
          [styles.grabbing]: isDragging,
        })}
        onMouseDown={handleMouseDown}
      >
        <ButtonPlusMinus isOpen={isOpen} toggleOpen={toggleDropdown} />
        <Checkbox
          checked={checked}
          setChecked={(value) =>
            dispatch(
              toggleMultipleLayersVisible({
                workspaceLayer: layer,
                layerIds,
                value,
              })
            )
          }
          style={{ marginRight: "4px" }}
        />
        <SortableKnob>
          <div className={styles.titleContainer}>
            <div className={styles.title}> {layer.post_title}</div>
            <div className={styles.chapter}> {layer.chapter_title}</div>
          </div>
        </SortableKnob>
      </div>

      {isOpen && (
        <div style={{ paddingTop: 12 }}>
          <ContentBlockByType contentBlock={layer.content_block} />
        </div>
      )}
    </div>
  );
});
