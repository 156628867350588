import React, { useCallback, useEffect, useState } from "react";
import styles from "./Auth.module.scss";
import { ModuleProps } from "./WindowAuth";
import Button from "../Button/Button";
import { TextField } from "../Input/TextField/TextField";

import CheckElement from "../CheckElement/CheckElement";
import useEnterKeyListener from "../../hooks/useEnterKeyListener";

import { sendNewPass } from "../../common/ApiService";
import { useLocation, useNavigate } from "react-router-dom";
import { InfoPlate } from "./ConfirmationModule";

export default function PassChangeModule({
  setStep,
  credentials,
  setCredentials,
}: ModuleProps) {
  const [touched, setTouched] = useState(false);
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const [timeOut, setTimeOut] = useState(5);
  const [hasPassChanged, setHasPassChanged] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const passwordValidation = [
    {
      validationMessage: "8-32 символа",
      isValid:
        !(credentials.password.length < 8) &&
        !(credentials.password.length > 32),
    },
    {
      validationMessage: "Латинские буквы и цифры",
      isValid:
        /[A-Za-z]/.test(credentials.password) &&
        /[0-9]/.test(credentials.password),
    },
    {
      validationMessage: "Строчные и заглавные буквы",
      isValid:
        /[A-Z]/.test(credentials.password) &&
        /[a-z]/.test(credentials.password),
    },
    {
      validationMessage: "Без пробелов",
      isValid: !/\s/.test(credentials.password),
    },
  ];

  const handleRegistration = useCallback(() => {
    !touched && setTouched(true);
    if (passwordValidation.every((rule) => rule.isValid)) {
      try {
        if (token) {
          sendNewPass({
            password: credentials.password,
            token,
          }).then(() => {
            setHasPassChanged(true);
          });
        }
      } catch (error) {
        console.error("Registration failed:", error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials.password, navigate, token, touched]);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!touched) {
      setTouched(true);
    }
    const value = e.target.value;
    if (!/[А-Яа-я]/g.test(value)) {
      setCredentials((prev) => ({
        ...prev,
        password: value,
      }));
    }
  };

  const handleKeyDown = () => {
    setIsEnterPressed(true);
  };

  const handleKeyUp = () => {
    setIsEnterPressed(false);
    handleRegistration();
  };

  useEnterKeyListener(handleKeyDown, handleKeyUp);

  useEffect(() => {
    if (timeOut === 1) {
      setStep("mailcheck");
      navigate("/login");
    }
  }, [navigate, setStep, timeOut]);

  useEffect(() => {
    if (hasPassChanged) {
      const intervalId = setInterval(() => {
        setTimeOut((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [hasPassChanged]);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.authModule}>
        {hasPassChanged ? (
          <InfoPlate
            title="Ваш пароль успешно изменен"
            text={`Вы будете перенаправлены на страницу входа через ${timeOut} сек.`}
          />
        ) : (
          <>
            {" "}
            <div className={styles.titleContainer}>
              <div className={styles.title}>Изменение пароля</div>
              {/* <button
                className={styles.back}
                onClick={() => {
                  setStep("mailcheck");
                }}
              >
                Назад
              </button> */}
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.fieldTitle}>Придумайте новый пароль</div>
              <TextField
                type="password"
                placeholder="Пароль"
                value={credentials.password}
                onChange={handlePasswordChange}
                onBlur={() => {
                  if (!touched) {
                    setTouched(true);
                  }
                }}
                isValid={
                  passwordValidation.every((rule) => rule.isValid) || !touched
                }
              />
              <div>
                {passwordValidation.map((item) => (
                  <CheckElement
                    key={item.validationMessage}
                    title={item.validationMessage}
                    status={
                      !touched ? "disabled" : item.isValid ? "success" : "wrong"
                    }
                  />
                ))}
              </div>
            </div>
            <Button
              title="Продолжить"
              size="large"
              onClick={handleRegistration}
              style={{
                width: "100%",
                backgroundColor: isEnterPressed ? "#0a1f70" : "",
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
