import React, { useState } from "react";
import MailCheckModule from "./MailCheckModule";
import LoginModule from "./LoginModule";
import RegModule from "./RegModule";
import PassRecoveryModule from "./PassRecoveryModule";
import SuccessRecoveryModule from "./SuccessRecoveryModule";
import ConfirmationModule from "./ConfirmationModule";
import PassChangeModule from "./PassChangeModule";

export type AuthStep =
  | "reg"
  | "login"
  | "mailcheck"
  | "passrecovery"
  | "successrecovery"
  | "passchange"
  | "confirmation";

type Credentials = { email: string; password: string; login: string };

export type ModuleProps = {
  setStep: React.Dispatch<React.SetStateAction<AuthStep>>;
  credentials: Credentials;
  setCredentials: React.Dispatch<React.SetStateAction<Credentials>>;
};

const Modules = (props: ModuleProps) => ({
  reg: <RegModule {...props} />,
  login: <LoginModule {...props} />,
  mailcheck: <MailCheckModule {...props} />,
  passrecovery: <PassRecoveryModule {...props} />,
  successrecovery: <SuccessRecoveryModule {...props} />,
  confirmation: <ConfirmationModule {...props} />,
  passchange: <PassChangeModule {...props} />,
});

function WindowAuth({ preStep = "mailcheck" }: { preStep: AuthStep }) {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    login: "",
  });
  const [step, setStep] = useState<AuthStep>(preStep);

  return Modules({ setStep, credentials, setCredentials })[step];
}
export default WindowAuth;
