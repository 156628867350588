import React, { useEffect, useRef, useState } from "react";
import styles from "./MiniSidebar.module.scss";

import { shortenString } from "../../common/utils";
import { TitlesProps } from "../SideBar/useSidebar";

export type MiniSidebarProps = {
  isOpen?: boolean;
  type: "atlas" | "constructor";
} & TitlesProps;

const MiniSidebar: React.FC<MiniSidebarProps> = ({
  isOpen,
  clusterTitle,
  chapterTitle,
  mapTitle,
  type,
}) => {
  const [height, setHeight] = useState<number>();
  const ref = useRef<HTMLDivElement | null>(null);
  const isMapOpen = clusterTitle && chapterTitle && mapTitle;

  useEffect(() => {
    if (ref.current) {
      const heightCount = ref.current.offsetHeight + 32;
      setHeight(heightCount < 124 ? 124 : heightCount);
    }
  }, [mapTitle]);

  return (
    <div
      className={isOpen ? styles.miniSidebarOpen : styles.miniSidebarClose}
      style={{
        height: isMapOpen ? height : 78,
      }}
    >
      <div className={styles.miniSidebarTitleContainer}>
        {isMapOpen ? (
          <div ref={ref}>
            <div className={styles.clusterTitle}>{clusterTitle}</div>
            <div className={styles.chapterTitle}>
              {shortenString(chapterTitle, 590, "400 15px Nunito Sans")}
            </div>
            <div className={styles.mapTitle}>
              {shortenString(mapTitle, 560, "700 15px Nunito Sans")}
            </div>
          </div>
        ) : (
          <>
            <div className={styles.miniSidebarTitle}>
              {type === "atlas" ? "Туристский атлас" : "Конструктор"}
            </div>
            <div className={styles.miniSidebarSubTitle}>Меню</div>
          </>
        )}
      </div>
      <div className={styles.miniSidebarIcon} />
    </div>
  );
};

export default MiniSidebar;
