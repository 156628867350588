import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import PageMapsDescription from "../components/PageMapsDescription/PageMapsDescription";
import { store } from "../store";
import { AbstractLayer } from "../pages/ConstructorPage";

const ExportToPDF = async (
  elementRef: HTMLDivElement | null,
  checkedLayersData: AbstractLayer[]
) => {
  if (!elementRef) return;

  try {
    document.body.style.cursor = "wait";
    const canvas = await html2canvas(elementRef, {
      useCORS: true,
      logging: true,
    });
    const imageData = canvas.toDataURL("image/png");

    const pdf = new jsPDF({
      orientation: "landscape",
      format: "a4",
    });

    const mapRatio = canvas.width / canvas.height;

    const imgWidth =
      mapRatio > 1.435 ? 287 : (canvas.width * 200) / canvas.height;
    const imgHeight =
      mapRatio > 1.435 ? (canvas.height * imgWidth) / canvas.width : 200;
    const yPos = (210 - imgHeight) / 2;
    const xPos = (297 - imgWidth) / 2;

    pdf.addImage(imageData, "PNG", xPos, yPos, imgWidth, imgHeight);

    // const textContent = "Карта из туристского атласа";
    // const canvasDiv = document.createElement("canvas");
    // const context = canvasDiv.getContext("2d");
    // canvasDiv.width = 1920;
    // canvasDiv.height = 30;
    // if (context) {
    //   context.font = "30px NunitoSans";
    //   context.fillText(textContent, 200, 30);
    // }
    // const imageDataDiv = canvasDiv.toDataURL("image/png");
    // pdf.addImage(imageDataDiv, "PNG", 0, 0, 297, 6);

    if (checkedLayersData && checkedLayersData.length > 0) {
      pdf.addPage();
      const specificationMap = document.createElement("div");
      specificationMap.style.width = "1920px";
      specificationMap.style.height = "1358px";

      ReactDOM.render(
        <Provider store={store}>
          <PageMapsDescription checkedLayersData={checkedLayersData} />
        </Provider>,
        specificationMap
      );

      document.body.appendChild(specificationMap);
      const specificationCanvas = await html2canvas(specificationMap, {
        useCORS: true,
      });
      const specificationImageData = specificationCanvas.toDataURL("image/png");
      pdf.addImage(specificationImageData, "PNG", 0, 0, 297, 210);
      document.body.removeChild(specificationMap);
    }

    pdf.save("Карта туристского атласа.pdf");
    document.body.style.cursor = "default";
  } catch (error) {
    console.error("Failed to export canvas as PDF:", error);
  }
};

export default ExportToPDF;
