import { configureStore } from "@reduxjs/toolkit";
import errorReducer from "./redux/error";
import mapReducer from "./redux/map";
import selectedFeatureSlice from "./redux/map/featureReducer";
import basemapSlice from "./redux/map/mapReducers";
import { menuReducer } from "./redux/menu";
import { modalWindowReducer } from "./redux/modal";
import { postReducer } from "./redux/post";
import authReducer from "./redux/auth/auth.reducer";
import constructorModeReducer from "./redux/constructorMode";

export const store = configureStore({
  reducer: {
    post: postReducer,
    menu: menuReducer,
    map: mapReducer,
    selectFeature: selectedFeatureSlice,
    basemapSlice: basemapSlice,
    errors: errorReducer,
    modalWindow: modalWindowReducer,
    auth: authReducer,
    constructorMode: constructorModeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
