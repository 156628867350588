import React from "react";

import styles from "./Menu.module.scss";
import ListMenu from "../listMenu/ListMenu";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import Loader from "../Loaders/Loader/Loader";

import { MenuItem } from "../../types/content-types";

import MenuCluster from "./MenuCluster";
import classNames from "classnames";

interface MenuProps {
  visible?: boolean;
  title?: string;
  type: "atlas" | "constructor";
}

const MenuModule: React.FC<MenuProps> = ({ type, visible }) => {
  const {
    data: menu,
    isLoading,
    postList,
    isPostsListLoading,
  } = useSelector((state: RootState) => state.menu);

  const { clusterId } = useSelector((state: RootState) => state.map);

  const names = {
    title: { atlas: "Туристский атлас", constructor: "Конструктор", menu: "" },
    chaptersTitle: {
      atlas: "Разделы и карты",
      constructor: "Слои конструктора",
    },
    notSelected: {
      atlas:
        "Выберите кластер на карте или в меню, чтобы увидеть доступные разделы и карты",
      constructor:
        "Выберите кластер в меню конструктора, чтобы увидеть доступные слои",
    },
  };

  const filteredChapters: MenuItem[] = (() => {
    if (clusterId && menu?.chapters) {
      const uniqueChapterIds = new Set(
        postList.map((post: MenuItem) => post.chapter_id)
      );
      return menu.chapters.filter((chapter) =>
        uniqueChapterIds.has(chapter.id)
      );
    }
    return [];
  })();

  const clustersOptions = menu?.clusters || [];
  const clustersCount = clustersOptions.length;

  return (
    <>
      {(isLoading || isPostsListLoading) && <Loader />}
      <div
        className={classNames(visible ? styles.menu : styles.menuDisable, {
          [styles.menuConstructor]: type === "constructor" && !!clusterId,
        })}
      >
        <div className={styles.headerMenu}>{names.title[type]}</div>

        <MenuCluster />

        {clusterId && (
          <div className={styles.chaptersTitle}>
            {names.chaptersTitle[type]}
          </div>
        )}
        {!clusterId && (
          <div className={styles.notSelected}>{names.notSelected[type]}</div>
        )}
        {type === "atlas" && (
          <ListMenu
            clusterId={clusterId}
            clustersCount={clustersCount}
            chapters={filteredChapters}
            posts={postList}
            isPostsListLoading={isPostsListLoading}
          />
        )}
      </div>
    </>
  );
};

export default MenuModule;
