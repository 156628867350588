import React, { useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import { select, deselect } from "../../../../redux/map/featureReducer";
import styles from "./RectangleDiagramsTest.module.css"

interface RectangleDiagramInterface {
    value: number | string,
    background: string,
    color: string,
    width: number,
    height: number,
    minValue: number,
    label?: string,
}

export const SimpleRectangleSelectedLabel = (props: RectangleDiagramInterface) => {
  const [isShown, setIsShown] = useState(false);

  const dispatch = useDispatch()

  let { width, height, color = "#ff0000", background, value, minValue, label } = props;

  let diagrammSizeforLabel = width;

  if (width < 12 && height < 12) {
    width = 12;
    height = 12;
  }

  if (width < 28) {
    diagrammSizeforLabel = 28;
  }
  if (String(value).length == 3) {
    if (diagrammSizeforLabel < 38) {
      diagrammSizeforLabel = 38;
    }
  }
  if (String(value).length == 4) {
    if (diagrammSizeforLabel < 48) {
      diagrammSizeforLabel = 48;
    }
  }
  if (String(value).length > 4) {
    if (diagrammSizeforLabel < 48) {
      diagrammSizeforLabel = 58;
    }
  }

  if (width > 400 && height > 400) {
    width = 250;
    height = 250;
  }

  const handleMouseEnter = () => {
    setIsShown(true);
    dispatch(select());
  }

  const handleMouseLeave = () => {
    setIsShown(false);
    dispatch(deselect());
  }

  return (
    <div 
      className={styles.rectangleDiagrammFull}
      onMouseEnter={() => handleMouseEnter() }
      onMouseLeave={() => handleMouseLeave()}
      >
      <div style={{ 
        width: `${diagrammSizeforLabel}px`, 
        height: `${diagrammSizeforLabel}px`, 
        backgroundColor: `${background}`, 
        color: `${color}`
      }} 
        className={styles.rectangleDiagramFrame}>
        <div className={styles.rectangleDiagramValue}>{String(value).replace('.', ',')}</div>        
      </div>
    {/* show label */}
      <div className={styles.rectangleDiagramLabel}>
        {label? 
          <div className={styles.rectangleDiagramLabelPlashka}>{label}</div> 
          : null}
      </div>
        
    </div>
  );
};