import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import styles from "./Auth.module.scss";
import { ModuleProps } from "./WindowAuth";
import { TextField } from "../Input/TextField/TextField";
import { sendCodeOnEmail, verifyEmailWithCode } from "../../common/ApiService";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { removeTokens } from "../../auth/tokenService";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/auth.reducer";

export function InfoPlate({ title, text }: { title: string; text: string }) {
  return (
    <div>
      <div className={styles.confirmationTitleContainer}>
        <div className={styles.confirmIcon}></div>
        <div className={styles.confirmationTitle}>{title}</div>
      </div>
      <div className={styles.confirmationText}>{text}</div>
    </div>
  );
}

export default function ConfirmationModule({ setStep }: ModuleProps) {
  const [state, setState] = useState({
    isCodeEntered: false,
    error: "",
    code: "",
    isConfirmed: false,
    timer: 5,
  });

  const userInfo = useSelector((store: RootState) => store.auth.userInfo);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, code: e.target.value }));
    if (e.target.value.length >= 4) {
      setState((prev) => ({ ...prev, isCodeEntered: true }));
    }
  };

  const handleCodeSend = () => {
    verifyEmailWithCode(state.code)
      .then(() => {
        setState((prev) => ({ ...prev, isConfirmed: true }));
        const intervalId = setInterval(() => {
          setState((prev) => ({ ...prev, timer: prev.timer - 1 }));
        }, 1000);

        return () => clearInterval(intervalId);
      })
      .catch((err) => {
        setState((prev) => ({ ...prev, error: "Введен неверный код" }));
      });
  };

  const handleRequestCode = useCallback(() => {
    if (userInfo?.email) {
      sendCodeOnEmail(userInfo?.email).then(() =>
        alert(
          `Мы отправили еще одно письмо с кодом на адрес электронной почты ${userInfo?.email}`
        )
      );
    }
  }, [userInfo]);

  useEffect(() => {
    if (state.timer === 1) {
      window.location.replace("/map");
    }
  }, [state.timer]);

  useEffect(() => {
    if (!!userInfo?.verified) {
      const intervalId = setInterval(() => {
        setState((prev) => ({ ...prev, timer: prev.timer - 1 }));
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [userInfo]);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.authModule}>
        {!!userInfo?.verified || !!state.isConfirmed ? (
          <InfoPlate
            title={"Ваш аккаунт подтвержден"}
            text={`Перенаправим вас на главную страницу через ${state.timer}  сек.`}
          />
        ) : (
          <>
            <div className={styles.title}>Подтверждение регистрации</div>
            <div className={styles.fieldContainer}>
              <div className={styles.fieldText}>
                Отправили письмо с 4-значным кодом подтверждения на почту{" "}
                <b>{userInfo?.email}</b>
              </div>
              <TextField
                onChange={handleInputChange}
                validationMessage={state.error}
                isValid={!state.error}
                value={state.code}
              />
              <Button
                title="Продолжить"
                size="large"
                onClick={handleCodeSend}
                status={!state.isCodeEntered ? "disabled" : "enabled"}
                style={{
                  width: "100%",
                }}
              />

              <div className={styles.fieldNotice}>
                Если вы не получили письмо, проверьте папку «Спам» или{" "}
                <span className={styles.footerLink} onClick={handleRequestCode}>
                  нажмите здесь
                </span>
                {", "} чтобы отправить письмо ещё раз
              </div>
            </div>
            <div className={styles.flexCenterContainer}>
              <div
                className={styles.footerLink}
                onClick={() => {
                  setStep("mailcheck");
                }}
              >
                Вернуться на страницу авторизации
              </div>
              <div
                className={styles.footerLink}
                onClick={() => {
                  removeTokens();
                  dispatch(logout());
                  navigate("/");
                }}
              >
                Выйти из аккаунта
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
