import { RMap, RLayerWMS, ROSM, RControl } from "rlayers";
import { fromLonLat } from "ol/proj";
import { AtlasMap } from "./config/interfaces";
import ZoomHomeButton from "../MapControls/ZoomHomeButton";
import "ol/ol.css";
import styles from "./Map.module.css";
import { useState } from "react";
import { getPost } from "./utils/get_map_component";
import MapLoader from "../Loaders/MapLoader/MapLoader";

interface MapInitialInterface {
  map_config: AtlasMap;
}

export function MapAzimut(map_configs: MapInitialInterface): JSX.Element {
  const map_config = map_configs.map_config;
  const center = fromLonLat(map_config.center);
  const isOSM = map_config.basemap === "osm";
  const hasLayers = map_config.layers.length > 0;
  const initial = {
    center: center,
    zoom: map_config.zoom.start,
  };
  const [view, setView] = useState(initial);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className={styles.mapWrap}>
      {isLoading && <MapLoader />}
      <RMap
        width={"100%"}
        height={"100%"}
        projection={map_config.projection}
        enableRotation={false}
        initial={initial}
        minZoom={map_config.zoom.min}
        maxZoom={map_config.zoom.max}
        noDefaultControls={true}
        view={[view, setView]}
        onRenderComplete={() => setIsLoading(false)}
      >
        <ZoomHomeButton onClick={() => setView(initial)} />
        <RControl.RZoom className={styles.zoom} />
        {hasLayers ? getPost(map_config) : null}
        {isOSM ? (
          <ROSM />
        ) : (
          <RLayerWMS
            url={map_config.basemap}
            params={{
              bgcolor: "0xD3D5DA",
              TRANSPARENT: false,
            }}
          ></RLayerWMS>
        )}
      </RMap>
    </div>
  );
}
