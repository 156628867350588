import { AtlasMap } from "./interfaces";

export const default_map_config: AtlasMap = {
  basemap: "",
  projection: "EPSG:3857",
  params: { background: "0xD3D5DA", transparent: true },
  zoom: { min: 3.5, max: 6, start: 4.3 },
  center: [39.7233,47.2313],
  extent: {"xmin": 35,"ymin": 41,"xmax": 60,"ymax": 50},
  layers: [],
};