import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";

import { getChaptersAndClusters, getPosts } from "../../common/ApiService";
import { MenuItem } from "../../types/content-types";

type State = {
  isSidebarOpen: boolean;
  isLoading: boolean;
  data: { chapters: MenuItem[] | null; clusters: MenuItem[] | null } | null;
  postList: MenuItem[];
  isPostsListLoading: boolean;
};

const initialState: State = {
  isLoading: false,
  data: null,
  isSidebarOpen: true,
  postList: [],
  isPostsListLoading: false,
};

export const getPostsListThunk = createAsyncThunk("posts/get", getPosts);

export const getMenuItemsThunk = createAsyncThunk(
  "menu/get",
  getChaptersAndClusters,
);

export const openSidebar = createAction<boolean>("TOGGLE_SIDEBAR");

export const menuReducer = createReducer(initialState, (builder) => {
  builder.addCase(getMenuItemsThunk.fulfilled, (state, action) => {
    state.data = action.payload;
    state.isLoading = false;
  });
  builder.addCase(getMenuItemsThunk.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getMenuItemsThunk.rejected, (state, action) => {
    state.isLoading = false;
  });

  builder.addCase(openSidebar, (state, action) => {
    state.isSidebarOpen = action.payload;
  });

  builder.addCase(getPostsListThunk.fulfilled, (state, action) => {
    state.postList = action.payload;
    state.isPostsListLoading = false;
  });
  builder.addCase(getPostsListThunk.pending, (state) => {
    state.isPostsListLoading = true;
  });
  builder.addCase(getPostsListThunk.rejected, (state) => {
    state.isPostsListLoading = false;
  });
});
