import { createAction, createReducer } from "@reduxjs/toolkit";

import { RootState } from "../../store";

export interface ConstructorModeState {
  isConstructorMode: boolean;
}

const initialState: ConstructorModeState = {
  isConstructorMode: false,
};

export const setIsConstructorMode = createAction<boolean>(
  "constructorMode/setIsConstructorMode",
);

const constructorModeReducer = createReducer(initialState, (builder) => {
  builder.addCase(setIsConstructorMode, (state, action) => {
    state.isConstructorMode = action.payload;
  });
});

export default constructorModeReducer;

export const useConstructorMode = (state: RootState) =>
  state.constructorMode.isConstructorMode;
