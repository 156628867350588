import React, { useEffect, useRef, useState } from "react";
import styles from "./Alert.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export default function Alert() {
  const error = useSelector<RootState, { message: string } | undefined>(
    (state) => state.errors.error
  );

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
  }, [error]);

  const timer = useRef<number>();

  useEffect(() => {
    window.clearTimeout(timer.current);
    if (error) {
      timer.current = window.setTimeout(() => setVisible(false), 10000);
    }
  }, [error]);

  const ruMessages = [
    {
      en: "Failed to fetch",
      ru: "Не удалось загрузить данные",
    },
  ];

  const message =
    ruMessages.find((msg) => msg.en === error?.message)?.ru || error?.message;

  return (
    <>
      {visible && (
        <div className={styles.alert} onClick={() => setVisible(false)}>
          <div className={styles.container}>
            <div className={styles.message}>{message}</div>
          </div>
        </div>
      )}
    </>
  );
}
