import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { Post } from "../../types/content-types";
import { mockData } from "./mock-data";
import { getPostById } from "../../common/ApiService";

const getTestInfo = async (): Promise<Post> => {
  return await new Promise((resolve) => {
    setTimeout(() => resolve(mockData), 500);
  });
};

type State = {
  isLoading: boolean;
  data: Post | null;
  activePostId: number | null;
};

const initialState: State = {
  isLoading: false,
  data: null,
  activePostId: null,
};

export const getPostThunk = createAsyncThunk("post/get", getPostById);

export const setPostNull = createAction("SET_POST_NULL");
export const getRegionInfoThunkTest = createAsyncThunk(
  "info/getTest",
  getTestInfo,
);

export const postReducer = createReducer(initialState, (builder) => {
  builder.addCase(getPostThunk.fulfilled, (state, action) => {
    state.activePostId = Number(action.payload.id);
    state.data = action.payload;
    state.isLoading = false;
  });
  builder.addCase(getPostThunk.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getPostThunk.rejected, (state, action) => {
    state.isLoading = false;
  });
  builder.addCase(setPostNull, (state) => {
    state.data = null;
    state.activePostId = null;
  });

  builder.addCase(getRegionInfoThunkTest.fulfilled, (state, action) => {
    state.data = action.payload;
    state.isLoading = false;
  });
  builder.addCase(getRegionInfoThunkTest.pending, (state) => {
    state.isLoading = true;
  });
});
