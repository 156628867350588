import BaseStyles from "../components/common.module.scss";
import Navbar from "../components/Navbar/Navbar";

export default function ContactsPage() {
  return (
    <>
      <div className={BaseStyles.splashScreenOpacity} />
      <Navbar activePage={"Контакты"} />
      <div>Контакты</div>
    </>
  );
}
