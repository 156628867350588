import React from "react";
import styles from "./ContentLoader.module.scss";

export default function ContentLoader() {
  return (
    <div className={styles.container}>
      <div className={styles.titlesContainer}>
        <div className={styles.clusterTitle} />
        <div className={styles.chapterTitle} />
        <div className={styles.mapTitleContainer}>
          <div className={styles.mapTitle} />
          <div className={styles.mapTitle} />
          <div className={styles.mapTitle} />
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.subBlock} />
        <div className={styles.subBlock} />
        <div className={styles.chapterTitle} />
        <div className={styles.legends}>
          <div className={styles.legend}>
            <div className={styles.icon} />
            <div className={styles.legendTitle} style={{ width: "250px" }} />
          </div>
          <div className={styles.legend}>
            <div className={styles.icon} />
            <div className={styles.legendTitle} style={{ width: "220px" }} />
          </div>
          <div className={styles.legend}>
            <div className={styles.icon} />
            <div className={styles.legendTitle} style={{ width: "300px" }} />
          </div>
        </div>
      </div>
    </div>
  );
}
