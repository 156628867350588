import React from "react";
import styles from "./CheckElement.module.scss";
import classNames from "classnames";

type CheckStatus = "success" | "wrong" | "disabled";

export type CheckElementProps = {
  title: string;
  status?: CheckStatus;
};

export default function CheckElement({
  title,
  status = "disabled",
}: CheckElementProps) {
  return (
    <div className={styles.container}>
      <div className={classNames(styles.icon, styles[status])} />
      <div className={styles.title}>{title}</div>
    </div>
  );
}
