import { ValueColorPair } from "../config/interfaces";

export const getColorByScale = (f: any, classified_prop: string, scale: ValueColorPair[], default_color: string) => {
    let value = f.values_[classified_prop];
    let color = default_color;
    scale.forEach((item, index) => {
      if (index !== 0) {
        let previousValueColorPair = scale[index - 1];
        if (previousValueColorPair.value < value && value <= item.value) {
          color = previousValueColorPair.color;
        }
        if (value > item.value) {
          color = item.color;
        }
      }
    });
    return color;
  }