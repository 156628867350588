import { useSelector } from "react-redux";
import styles from "./ButtonScreenshot.module.scss";
import { RootState } from "../../store";
import ExportToPDF from "../../common/ExportToPDF";
import { useMemo } from "react";

export default function ButtonScreenshot({
  elementRef,
}: {
  elementRef: HTMLDivElement | null;
}) {
  const { abstractLayers } = useSelector((state: RootState) => state.map);

  const layers = useSelector(
    (state: RootState) => state.map.map_config?.layers
  );

  const checkedLayersData = useMemo(() => {
    const checkedLayers = layers
      ?.filter((layer) => layer.visible === true)
      ?.map((checkedLayer) => checkedLayer.layer);

    return abstractLayers.filter((data) =>
      data.map_layers.some((layer) => checkedLayers?.includes(layer.layer))
    );
  }, [abstractLayers, layers]);

  const handleExportPDF = () => {
    if (elementRef) {
      ExportToPDF(elementRef, checkedLayersData);
    }
  };

  return <div className={styles.screenshotButton} onClick={handleExportPDF} />;
}
