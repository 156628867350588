import React, { useCallback, useEffect } from "react";

import Main from "../components/Map/MapOverLayer";

import styles from "../components/Map/Map.module.css";
import MapControls from "../components/MapControls/MapControls";
import { useDispatch, useSelector } from "react-redux";

import { getPostsListThunk, openSidebar } from "../redux/menu";
import { AppDispatch } from "../store";
import { useNavigate } from "react-router-dom";
import { setDefaultMap } from "../redux/map";
import Sidebar from "../components/SideBar/Sidebar";
import { setPostNull } from "../redux/post";

import { useSidebar } from "../components/SideBar/useSidebar";
import { Content } from "../components/SideBar/Content";
import {
  setIsConstructorMode,
  useConstructorMode,
} from "../redux/constructorMode";

export default function MapPage() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isConstructorMode = useSelector(useConstructorMode);

  const {
    clusterTitle,
    chapterTitle,
    mapTitle,
    clusterId,
    isSidebarOpen,
    isMenu,
  } = useSidebar();

  const goBackToMenu = useCallback(() => {
    navigate("/map");
    dispatch(setDefaultMap());
    dispatch(setPostNull());
  }, [dispatch, navigate]);

  const onSidebarClose = useCallback(() => {
    dispatch(openSidebar(!isSidebarOpen));
  }, [dispatch, isSidebarOpen]);

  const ComponentToRender = !isMenu ? (
    <Content
      clusterTitle={clusterTitle}
      chapterTitle={chapterTitle}
      mapTitle={mapTitle}
    />
  ) : null;

  useEffect(
    function fetchPostsList() {
      if (clusterId && clusterId !== null) {
        dispatch(getPostsListThunk({ clusterId }));
      }
    },
    [clusterId, dispatch]
  );

  useEffect(() => {
    if (isMenu) {
      dispatch(setDefaultMap());
    }
  }, [dispatch, isMenu]);

  useEffect(() => {
    if (isConstructorMode) {
      dispatch(setIsConstructorMode(false));
    }
  }, [isConstructorMode, dispatch]);

  return (
    <>
      <Main>
        <MapControls />
        <div className={styles.containerLeft} />
        <div className={styles.containerRight}>
          <Sidebar
            isMenu={isMenu}
            onGoBack={goBackToMenu}
            isSidebarOpen={isSidebarOpen}
            onSidebarClose={onSidebarClose}
            Content={ComponentToRender}
          />
        </div>
      </Main>
    </>
  );
}
