import React from "react";
import styles from "./ViewContainer.module.scss";

export type ViewContainerProps = { children: any };

const ViewContainer: React.FC<ViewContainerProps> = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Предварительный просмотр элемента:</div>
      <div className={styles.contentContainer}>{children}</div>
    </div>
  );
};

export default ViewContainer;
