import BaseStyles from "../components/common.module.scss";
import Navbar from "../components/Navbar/Navbar";
import NewsWindow from "../components/NewsWindow/NewsWindow";
import PageContainer from "../components/PageContainer/PageContainer";

export default function NewsPage() {
  return (
    <>
      <div className={BaseStyles.splashScreenOpacity} />
      <Navbar activePage={"Новости"} />
      <PageContainer>
        <NewsWindow />
      </PageContainer>
    </>
  );
}
