import React, { useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import Feature from "ol/Feature";
import { select, deselect, setSelectedFeature } from "../../../../redux/map/featureReducer";
import styles from "./RectangleDiagramsTest.module.css"
import { set_center } from "../../../../redux/map/mapReducers";

interface RectangleDiagramInterface {
    feature: any,
    value: number,
    background: string,
    color: string,
    width: number,
    height: number,
    minValue: number,
    label?: string,
}

export const SimpleRectangle = (props: RectangleDiagramInterface) => {

  const isSelected = useSelector((state: any) => state.selectFeature.isSelected);
  const dispatch = useDispatch()

  let { feature, width, height, color = "#ff0000", background, value, minValue, label } = props;

  const showValue = value > minValue;

  let diagrammSizeforLabel = width;

  // default font size
  let fontSize = 14;

  if (width < 12 && height < 12) {
    width = 12;
    height = 12;
  }

  if (width < 28) {
    diagrammSizeforLabel = 28;
    fontSize = 12;
  }

  if (width >= 48 ) {
    fontSize = 18;
  }

  if (width > 400 && height > 400) {
    width = 250;
    height = 250;
  }

  const handleMouseEnter = () => {
    dispatch(select());
    dispatch(setSelectedFeature(feature));
    if (feature['geometry']['coordinates']) {
      dispatch(set_center(feature['geometry']['coordinates']));
    }
    
  }

  const handleMouseLeave = () => {
    dispatch(deselect());
  }

  return (
    <div 
      className={styles.rectangleDiagrammFull}
      onMouseEnter={() => handleMouseEnter() }
      onMouseLeave={() => handleMouseLeave()}>

    <div style={{ 
      width: `${width}px`, 
      height: `${height}px`, 
      backgroundColor: `${background}`, 
      color: `${color}`,
      fontSize: `${fontSize}`,
      padding: '2px'
    }} 
      className={styles.rectangleDiagramFrame}>
      {showValue ? (<div className={styles.rectangleDiagramValue}>{String(value).replace('.', ',')}</div>) : null}             
    </div>   
    </div>
  );
};