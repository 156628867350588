import React from "react";
import { Block } from "../../types/content-types";
import ContentBlockBase from "../ContentBlockBase";
import styles from "./ImageBlock.module.scss";

interface ImageBlockProps {
  contentBlock: Block;
}

const ImageBlock: React.FC<ImageBlockProps> = ({ contentBlock }) => {
  const handleContextMenu = (event: React.MouseEvent<HTMLImageElement>) => {
    event.preventDefault();
  };

  return (
    <ContentBlockBase contentBlock={contentBlock}>
      <img
        src={contentBlock.url}
        alt={contentBlock.description ?? "Image"}
        className={styles.image}
        draggable={false}
        onContextMenu={handleContextMenu}
      />
    </ContentBlockBase>
  );
};

export default ImageBlock;
