import React, { useState } from "react";
import styles from "./SelectorScale.module.css";
import ContentBlockBase from "../../ContentBlockBase";

interface Props {
  contentBlock: any;
}

const SelectorScale: React.FC<Props> = ({ contentBlock }) => {
  const [selectedValues, setSelectedValues] = useState(
    new Set(contentBlock.x_data)
  );

  const backgroundColor = [
    "rgba(214, 48, 49, 0.2)",
    "rgba(214, 48, 49, 0.4)",
    "rgba(214, 48, 49, 0.6)",
    "rgba(214, 48, 49, 0.8",
    "rgba(214, 48, 49, 1)",
  ];

  const x_legend = ["50", "100", "200", "400"];

  const toggleSelection = (value: number) => {
    const newSelectedValues = new Set(selectedValues);
    if (newSelectedValues.has(value)) {
      newSelectedValues.delete(value);
    } else {
      newSelectedValues.add(value);
    }
    setSelectedValues(newSelectedValues);
  };

  return (
    <ContentBlockBase contentBlock={contentBlock}>
      <div className={styles.selectorScale}>
        <div className={styles.buttonContainer}>
          {contentBlock.x_data.map((value: any, index: number) => (
            <div
              className={styles.button}
              style={{
                background: selectedValues.has(value)
                  ? backgroundColor[index]
                  : "rgb(182, 179, 179)",
              }}
              onClick={() => toggleSelection(value)}
              key={index}
            />
          ))}
        </div>
        <div className={styles.labelContainer}>
          {x_legend.map((value: any) => (
            <div className={styles.label} key={value}>
              {value}
            </div>
          ))}
        </div>
      </div>
    </ContentBlockBase>
  );
};

export default React.memo(SelectorScale);
