import { useState } from "react";
import styles from "./LinkElement.module.scss";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";

export default function LinkElement({
  postId,
  postTitle,
  goToPostById,
}: {
  postId: number;
  postTitle: string;
  goToPostById: (postId: number) => void;
}) {
  const [tooltip, setTooltip] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleLoginNavigate = () => {
    navigate("/login");
  };

  const accessedLink = !!postId;

  return (
    <div
      onMouseEnter={!accessedLink ? () => setTooltip(true) : undefined}
      onMouseLeave={!accessedLink ? () => setTooltip(false) : undefined}
    >
      <div
        className={
          accessedLink ? styles.mapLinkContainer : styles.lockMapLinkContainer
        }
        onClick={accessedLink ? () => goToPostById(postId) : undefined}
      >
        <div
          className={accessedLink ? styles.mapLinkIcon : styles.lockLinkIcon}
        />
        <div className={accessedLink ? styles.mapLink : styles.lockLink}>
          {postTitle}
        </div>
      </div>
      {!accessedLink && (
        <div
          className={
            tooltip ? styles.tooltipContainer : styles.tooltipContainerDisabled
          }
        >
          <div className={styles.title}>
            Для получения доступа к карте, пожалуйста, войдите в систему
          </div>
          <div className={styles.buttonContainer}>
            <Button title={"Войти"} onClick={handleLoginNavigate} />
          </div>
        </div>
      )}
    </div>
  );
}
