import React, { useCallback } from "react";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import Feature from "ol/Feature";
import { RLayerVector, RFeature, ROverlay } from "rlayers";
import { Layer } from "../../config/interfaces";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import { SimpleRectangle, SimpleRectangleSelectedLabel } from "../../elements/diagrams";
import { useSelector} from "react-redux";
import {geojsonLayer} from "../../utils/parce_geojson";


interface AtlasLayerInterface {
  layers: Layer[];
}

export function AgroDiagramms(layers: AtlasLayerInterface) {
  // layer1
  const layer = layers.layers[0];

  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });
  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { name: string, agrofarm: number } }[];
  };
  const fetchData = fetch(layer_url).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ features: [] } as inputDataType);
  const [upSelected, setSelected] = React.useState(false);
  const [current, setCurrent] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData.then((r) => setData(r));
    setSelected(true);
  }, []);

  const [features, setFeatures] = React.useState(() =>
    Object.keys(data.features).map(
      (f) =>
        new Feature({
          geometry: new Point(fromLonLat([44.2558, 46.3078])),
          name: f,
        })
    )
  );

  const isSelected = useSelector((state: any) => state.selectFeature.isSelected);
  const selectedFeature = useSelector((state: any) => state.selectFeature.feature);

  return (
    <>
      <RLayerVector
        zIndex={2}
        visible={false}
      >
        {isSelected ?
            <RFeature geometry={
              new Point(fromLonLat([
                selectedFeature.geometry.coordinates[0], 
                selectedFeature.geometry.coordinates[1]
                ]))}>
            <ROverlay 
            positioning="center-left">
              <SimpleRectangleSelectedLabel 
                value={selectedFeature.properties.agrofarm} 
                width={selectedFeature.properties.agrofarm} height={selectedFeature.properties.agrofarm} 
                color={'#ffffff'} 
                background={"#0334b9"}
                minValue={24}
                label={selectedFeature.properties.name}/>
           </ROverlay>
          </RFeature>
          : 
          <RFeature geometry={new Point(fromLonLat([0, 0]))}>
            <ROverlay positioning="center-left"></ROverlay>
          </RFeature>
           }
        {data.features.map((f) => (
        <RFeature 
          key={f.id}
          geometry={new Point(fromLonLat(f.geometry.coordinates))}
          properties={f.properties}
          >
          <ROverlay 
          positioning="center-left">
              <SimpleRectangle 
                feature={f}
                value={f.properties.agrofarm} 
                width={f.properties.agrofarm} height={f.properties.agrofarm} 
                color={'#ffffff'} 
                background={"#0334b9"}
                minValue={24}
                label={f.properties.name}/>
           </ROverlay>
          </RFeature>
        ))}

      </RLayerVector>
    </>
  );
}
