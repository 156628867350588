import { useEffect, useRef, useState } from "react";
import styles from "./MenuButton.module.scss";
import useOutsideClick from "../../hooks/useOutsideClick";
import { MenuButtonType } from "./MapControls";
import classNames from "classnames";

export default function MenuButton({
  options,
  onSelect,
  type,
}: {
  options: MenuButtonType[];
  onSelect: (option: MenuButtonType) => void;
  type: "burgerMenu" | "userMenu";
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const mainRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(mainRef, () => setIsOpen(false));

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: MenuButtonType) => {
    setIsOpen(false);
    onSelect(option);
  };

  useEffect(() => {
    const element = dropdownRef.current;
    const height = element ? element.offsetHeight : 0;
    setHeight(height);
  }, []);

  return (
    <div ref={mainRef}>
      <div
        className={classNames(styles.menuButton, styles[type], {
          [styles.menuButtonOpen]: isOpen,
          [styles[`${type}Open`]]: isOpen,
        })}
        onClick={toggleDropdown}
      />
      {options.length && (
        <div
          className={styles.transitionContainer}
          style={{
            maxHeight: isOpen ? height : 0,
            transitionDuration: `${height * 1.5}ms`,
          }}
        >
          <div ref={dropdownRef} className={styles.dropdown}>
            {options.map((option, index) => (
              <div
                key={index}
                className={styles.listItem}
                onClick={() => handleSelect(option)}
              >
                <div className={styles.itemTitle}>{option.title}</div>
                <div className={styles.checkbox} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
