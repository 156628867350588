import React from "react";
import commonStyles from "./common.module.scss";
import Header from "./TextComponents/Header";
import SubHeader from "./TextComponents/SubHeader";
import { Block } from "../types/content-types";
import Description from "./TextComponents/Description";
import Paragraph from "./TextComponents/Paragraph";

export default function ContentBlockBase({
  contentBlock,
  children,
}: {
  contentBlock: Block;
  children: React.ReactElement;
}) {
  const { header, sub_header, description, paragraph } = contentBlock;

  return (
    <div className={commonStyles.contentBlock}>
      {(header || sub_header || description) && (
        <div>
          {header && <Header text={header} />}
          {sub_header && <SubHeader text={sub_header} />}
          {description && <Description text={description} />}
        </div>
      )}
      <div>{children}</div>
      {paragraph && <Paragraph text={paragraph} />}
    </div>
  );
}
