import { InfoWindow } from "../../Map/config/interfaces";

export const getPopupData = (
  infowindow: InfoWindow,
  feature_properties: any,
) => {
  Object.freeze(infowindow);

  const popupData = { ...infowindow }; // 👈 create copy

  for (var key in popupData) {
    if (key === "title") {
      popupData[key] = feature_properties[infowindow[key]];
      if (popupData[key] == null) {
        popupData[key] = infowindow[key];
      }
    }
    if (key === "subTitle") {
      if (infowindow.subTitle) {
        popupData[key] = feature_properties[infowindow.subTitle];
      }
    }
    if (key === "description") {
      if (infowindow.description) {
        popupData[key] = feature_properties[infowindow.description];
      }
    }
    if (key === "paragraph") {
      if (infowindow.paragraph) {
        popupData[key] = feature_properties[infowindow.paragraph];
      }
    }
    if (key === "subscription") {
      if (infowindow.subscription) {
        popupData[key] = feature_properties[infowindow.subscription];
      }
    }
    if (key === "smallPopup") {
      if (infowindow.smallPopup) {
        popupData[key] = true;
      }
    }
  }
  return popupData;
};
