import AddPostForm from "../edit/EditorForms/AddPostForm";
import Main from "../components/Map/MapOverLayer";
import AddMapForm from "../edit/EditorForms/AddMapForm";

import { useMenu } from "../components/SideBar/useMenu";

export default function EditorPage({ type }: { type: "addMap" | "addPost" }) {
  useMenu();
  const forms = {
    addMap: <AddMapForm />,
    addPost: <AddPostForm />,
  };

  return (
    <>
      <Main>
        <div
          style={{
            pointerEvents: "auto",
            margin: "20px auto",
            borderRadius: "10px",
          }}
        >
          {forms[type]}
        </div>
      </Main>
    </>
  );
}
