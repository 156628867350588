import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bbox: { xmin: 0, ymin: 0, xmax: 0, ymax: 0 },
  center: [],
};

export const basemapSlice = createSlice({
  name: "basemapSlice",
  initialState,
  reducers: {
    set_bbox: (state, action) => {
      state.bbox = action.payload;
    },
    set_center: (state, action) => {
      state.center = action.payload;
    },
  },
});

export const { set_bbox, set_center } = basemapSlice.actions;

export default basemapSlice.reducer;
