import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deselect } from "../../../../redux/map/featureReducer";
import { setModalState } from "../../../../redux/modal";
import PopUp from "../../../PopUp/PopUp";
import styles from "../../elements/diagrams/RectangleDiagramsTest.module.css";
import { InfoWindow } from "../../config/interfaces";
import { getPopupData } from "../../utils";

interface InfowindowCreatorInterface {
    infowindow: InfoWindow,
    properties: any, //fix it!
}

export const CustomInfoWindow = (props: InfowindowCreatorInterface) => {
  const [isShown, setIsShown] = useState(false);

  const infowindow = props.infowindow;
  const properties = props.properties;

  const popupData = getPopupData(infowindow, properties);


  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setIsShown(true);
  };

  const handleMouseLeave = () => {
    setIsShown(false);
    dispatch(deselect());
  };

  const handleModalOpen = (title: string, text: string) => {
    dispatch(
      setModalState({
        isVisible: true,
        title: title,
        content: <p> {text} </p>,
      })
    );
  };

  
  const readMore = () => {
    if (infowindow.modal_paragraph) {
        handleModalOpen(properties[infowindow.title], properties[infowindow.modal_paragraph]);
    }
    else {
      handleModalOpen('', '');
    }
  }
  

  return (
    <div 
      style={{marginLeft: '10px', transform: 'translateY(-10px)'}}
    >
    <div
      className={styles.rectangleDiagrammFull}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      {infowindow.modal ?
      <PopUp popupData={popupData} onClick={readMore} />
      :
      <PopUp popupData={popupData} />
      }
    </div>
    </div>
  );
};
