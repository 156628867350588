import { AnyAction } from "redux";

export type ErrorState = {
  error?: Error;
};

interface Action extends AnyAction {
  error?: Error;
}

export default function errorReducer(
  state = {} as ErrorState,
  action: Action,
): ErrorState {
  if (action.error) {
    return { error: action.error };
  } else {
    return state;
  }
}
