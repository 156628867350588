import { createSlice } from "@reduxjs/toolkit";

type State = {
  isVisible: boolean;
  content: React.ReactNode | string | null;
  title: string | null;
  subtitle?: string | null;
  image?: { url: string };
};

const initialState: State = {
  isVisible: false,
  content: null,
  title: null,
  subtitle: null,
  image: undefined,
};

const modalWindowDataSlice = createSlice({
  name: "modalWindow",
  initialState,
  reducers: {
    setModalState: (state, action: { payload: State }) => {
      state.content = action.payload.content;
      state.title = action.payload.title;
      state.isVisible = action.payload.isVisible;
      state.image = action.payload.image;
    },
    open: (state) => {
      state.isVisible = true;
    },
    close: (state) => {
      state.isVisible = false;
    },
  },
});

export const modalWindowReducer = modalWindowDataSlice.reducer;

export const { open, close, setModalState } = modalWindowDataSlice.actions;
