import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSelected: false,
  feature: {},
};

export const selectedFeatureSlice = createSlice({
  name: "selectFeature",
  initialState,
  reducers: {
    select: (state) => {
      state.isSelected = true;
    },
    deselect: (state) => {
      state.isSelected = false;
    },
    setSelectedFeature: (state, action) => {
      state.feature = action.payload;
    },
  },
});

export const { select, deselect, setSelectedFeature } =
  selectedFeatureSlice.actions;

export default selectedFeatureSlice.reducer;
