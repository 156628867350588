import React from "react";
import styles from "./MapLoader.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const MapLoader: React.FC = () => {
  const { isSidebarOpen } = useSelector((state: RootState) => state.menu);

  return (
    <div
      className={styles.loaderContainer}
      style={{ right: isSidebarOpen ? "514px" : 0 }}
    >
      <div className={styles.loader} />
    </div>
  );
};

export default MapLoader;
