import React, { useState } from "react";
import styles from "./Auth.module.scss";
import { ModuleProps } from "./WindowAuth";
import { makeRequest } from "../../common/ApiService";
import Button from "../Button/Button";
import { TextField } from "../Input/TextField/TextField";
import useEnterKeyListener from "../../hooks/useEnterKeyListener";

export default function MailCheckModule({
  setStep,
  credentials,
  setCredentials,
}: ModuleProps) {
  const [touched, setTouched] = useState(false);
  const [isEnterPressed, setIsEnterPressed] = useState(false);

  const emailNotValid = !credentials.email.match(
    /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
  );

  const checkEmail = async () => {
    !touched && setTouched(true);
    if (emailNotValid) return;

    const emailBase64 = btoa(credentials.email);

    const { verified } = await makeRequest<{ verified: boolean }>(
      `/api/users/verify?data=${emailBase64}`,
      {
        method: "post",
      },
      true
    );

    if (!!verified) {
      setStep("login");
    } else {
      setStep("reg");
    }
  };

  const handleKeyDown = () => {
    setIsEnterPressed(true);
  };

  const handleKeyUp = () => {
    setIsEnterPressed(false);
    checkEmail();
  };

  useEnterKeyListener(handleKeyDown, handleKeyUp);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.authModule}>
        <div className={styles.title}>Вход / Регистрация</div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldTitle}>Электронная почта </div>
          <TextField
            type="text"
            placeholder="user@mail.com"
            value={credentials.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCredentials((prev) => ({
                ...prev,
                email: e.target.value,
                login: e.target.value,
              }));
            }}
            onBlur={() => {
              if (!touched) {
                setTouched(true);
              }
            }}
            isValid={!emailNotValid || !touched}
            validationMessage="Неправильный формат почты"
          />
        </div>
        <Button
          title="Продолжить"
          size="large"
          onClick={checkEmail}
          style={{
            width: "100%",
            backgroundColor: isEnterPressed ? "#0a1f70" : "",
          }}
        />
      </div>
    </div>
  );
}
