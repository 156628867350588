import { AtlasLayer } from "../Layer";
import { AtlasMap } from "../config/interfaces";
import { MapCreator } from "../maps/common";
import { KalmGURedkieZhivotnye } from "../maps/kalmgu";
import { NNGU_OOPT, NnguPopulation } from "../maps/nngu";
import { Hotels, OGUTransport } from "../maps/ogu";
import { PGUMountainsLayer, Population } from "../maps/pgu";
import {
  RggmuBalneologia,
  ZapovednikDiagramms
} from "../maps/rggmu";
import {
  Agro,
  AgroDiagramms,
  ObyectyVinnogoTurizma,
  ReligionArmyane,
  ReligionPravoslavnye,
  SGUAirlines,
  STGUSelskiyTurizm,
  STGUVinoProizvodstva,
  Turizm
} from "../maps/sgu";
import { VITIGTurizm } from "../maps/vitig";
import {
  YufuDelovoyTurizm,
  YufuPopulation,
  YufuRamsar,
  YufuRastitelnost,
  YufuTurmarshrut
} from "../maps/yufu";


export const getPost = (map_config: AtlasMap) => {
    if (map_config.id) {
      switch (map_config.id) {
        case 80:
          return <STGUSelskiyTurizm layers={map_config.layers} />;
        case 81:
          return <STGUVinoProizvodstva layers={map_config.layers} />;
        case 82:
          return <ObyectyVinnogoTurizma layers={map_config.layers} />;
        case 73:
          return <YufuTurmarshrut layers={map_config.layers}/>
        case 78:
          return <YufuRastitelnost layers={map_config.layers}/>
        case 79:
          return <YufuRamsar layers={map_config.layers} />;
        case 20:
          return <PGUMountainsLayer layers={map_config.layers} />;
        case 37:
          return <SGUAirlines layers={map_config.layers} />;
        case 38:
          return <Turizm layers={map_config.layers} />;
        case 39:
          return <Turizm layers={map_config.layers} />;
        case 40:
          return <Turizm layers={map_config.layers} />;
        case 41:
          return <Agro layers={map_config.layers} />;
        case 43:
          return <Agro layers={map_config.layers} />;
        case 44:
          // паломничество армян
          return <ReligionArmyane layers={map_config.layers} />;
        case 45:
          // паломничество христиан
          return <ReligionPravoslavnye layers={map_config.layers} />;
        case 46:
          return <YufuPopulation layers={map_config.layers} />;
        case 47:
          return <YufuPopulation layers={map_config.layers} />;
        case 48:
          return <YufuPopulation layers={map_config.layers} />;
        case 49:
          // количество сельхоз производителей
            return <AgroDiagramms layers={map_config.layers} />;
        case 50:
          //население городское 2019 пгу
          return <Population layers={map_config.layers} />
        case 51:
            //население сельское 2019 пгу
          return <Population layers={map_config.layers} />
        case 54:
            //заповедники диаграммы с подписями
          return <ZapovednikDiagramms layers={map_config.layers} />
        case 57:
          return <OGUTransport layers={map_config.layers}/>
        case 59:
          return <KalmGURedkieZhivotnye layers={map_config.layers}/>
        case 61:
          return <NNGU_OOPT layers={map_config.layers}/>
        case 62:
            return <NnguPopulation layers={map_config.layers}/>
        case 63:
            return <NnguPopulation layers={map_config.layers}/>
        case 66:
            return <VITIGTurizm layers={map_config.layers}/>
        // new maps
        case 68:
            return <YufuDelovoyTurizm layers={map_config.layers}/>
        case 70:
          return <RggmuBalneologia layers={map_config.layers}/>
        default:
          return <MapCreator layers={map_config.layers}/>;
      }
    }
  };