import React, { useCallback } from "react";
import GeoJSON from "ol/format/GeoJSON";
import { RLayerVector, RFeature, ROverlay, RStyle } from "rlayers";
import { Geometry } from "ol/geom";
import Feature from "ol/Feature";
import { Layer } from "../../config/interfaces";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import { 
  SimpleRectangle, 
  SimpleRectangleSelectedLabelnoValue 
} from "../../elements/diagrams";
import { useSelector} from "react-redux";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function RggmuBalneologia(layers: AtlasLayerInterface) {
  // layer1
  const layer = layers.layers[0];
  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=700&outputFormat=application%2Fjson`;

  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });
  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { name: string, color_hex: string } }[];
  };
  const fetchData = fetch(layer_url).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ features: [] } as inputDataType);
  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);
  RFeature.hitTolerance = 0;

  // layer1
  const layer2 = layers.layers[1];
  const layer_url2 = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer2.workspace}%3A${layer2.layer}&maxFeatures=700&outputFormat=application%2Fjson`;

  const parser2 = new GeoJSON({ featureProjection: "EPSG:4326" });
  const fetchData2 = fetch(layer_url).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data2, setData2] = React.useState({ features: [] } as inputDataType);
  const [current2, setCurrent2] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData2.then((r) => setData2(r));
  }, []);

  const renderStyle = useCallback((f: any) => {
    let color = "#ffffff";
    return (
      <>
      <RStyle.RFill color={f.values_.color_hex}/>
      <RStyle.RStroke width={0.5} color={'#f5f5f5'}/>
      </>
      ); 
  }, [data2])

  const isSelected = useSelector((state: any) => state.selectFeature.isSelected);
  const selectedFeature = useSelector((state: any) => state.selectFeature.feature);

  return (
    <>
    <RLayerVector
        zIndex={2}
        format={parser}
        url={layer_url}
        visible={false}
      >
        {isSelected ?
            <RFeature geometry={
              new Point(fromLonLat([
                selectedFeature.geometry.coordinates[0], 
                selectedFeature.geometry.coordinates[1]
                ]))}>
            <ROverlay 
            positioning="top-left">
              <SimpleRectangleSelectedLabelnoValue 
                width={20} height={20} 
                color={'#ffffff'} 
                background={selectedFeature.properties.color_hex}
                minValue={24}
                label={selectedFeature.properties.name}/>
           </ROverlay>
          </RFeature>
          : 
          <RFeature geometry={new Point(fromLonLat([0, 0]))}>
            <ROverlay positioning="center-left"></ROverlay>
          </RFeature>
           }
        {data.features.map((f) => (
        <RFeature 
          key={f.id}
          geometry={new Point(fromLonLat(f.geometry.coordinates))}
          properties={f.properties}
          >
          <ROverlay 
          positioning="top-left">
              <SimpleRectangle 
                feature={f}
                value={0} 
                width={16} height={16} 
                color={'#ffffff'} 
                background={f.properties.color_hex}
                minValue={0}
                label={f.properties.name}/>
           </ROverlay>
          </RFeature>
        ))}
      </RLayerVector>

      <RLayerVector
        zIndex={1}
        format={parser2}
        url={layer_url2}
        onPointerEnter={useCallback((e: any) => setCurrent2(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current2 === e.target && setCurrent2(null),
          [current2]
        )}
      >
        <RStyle.RStyle
          render={renderStyle}
        />
      </RLayerVector>
    </>
  );
}
