import { useFormikContext } from "formik";
import ViewContainer from "../../components/ViewContainer/ViewContainer";
import { Post } from "../../types/content-types";
import { TextArea } from "../Components";
import { SubFormProps } from "../types";
import { useEditorField } from "./useEditorField";
import { useState } from "react";
import { z } from "zod";
import DonutChartBlock from "../../components/Charts/DonutChart/DonutChartBlock";

const datasetChartSchema = z
  .object({
    data: z.array(z.number()),
    color: z.array(z.string()),
    tooltip: z.array(z.string()).optional(),
  })
  .strict();

const donutChartPropsSchema = z
  .object({
    title: z.string().optional(),
    radius: z.number().optional(),
    cutout: z.string().optional(),
    labels: z.array(z.string()).optional(),
    datasets: z.array(datasetChartSchema),
    not_displayed_data: z.array(z.number()).optional(),
    center_text: z.string().optional(),
    center_text_font_size: z.number().optional(),
    aspect: z.number().optional(),
    is_data_labels_enabled: z.boolean().optional(),
    data_labels_description: z.string().optional(),
    data_labels_font_size: z.number().optional(),
    data_labels_color: z.string().optional(),
    is_hover_active: z.boolean().optional(),
    is_tooltip_enabled: z.boolean().optional(),
    is_labels_enabled: z.boolean().optional(),
    labels_position: z.enum(["start", "center", "end"]).optional(),
  })
  .strict();

export default function DonutChartForm({
  index,
  nestedIndex = 0,
  isNested = false,
}: SubFormProps) {
  const [error, setError] = useState("");
  const [componentKey, setComponentKey] = useState("1");
  const { setFieldValue, handleChange } = useFormikContext<Post>();

  const { fieldName, blockValue } = useEditorField({
    index,
    nestedIndex,
    isNested,
  });

  const setData = (value: string) => {
    try {
      const parsedValue = JSON.parse(value);
      const result = donutChartPropsSchema.safeParse(parsedValue);
      if (result.success) {
        setError("");
        setFieldValue(fieldName("donut_chart"), parsedValue);
        setComponentKey(
          parsedValue?.aspect +
            parsedValue?.center_text +
            parsedValue?.center_text_font_size
        );
      } else {
        let errorMessages = "";

        if (result.error.errors && result.error.errors.length > 0) {
          errorMessages = result.error.errors
            .map((error, index) => {
              return `Ошибка ${index + 1}: ${error.message}`;
            })
            .join(" | ");
        }

        setError(`${errorMessages}`);
      }
    } catch (error) {
      setError("Неправильный формат данных, ошибка в синтаксисе");
    }
  };

  return (
    <div>
      <ViewContainer>
        {blockValue && (
          <DonutChartBlock key={componentKey} contentBlock={blockValue} />
        )}
      </ViewContainer>
      <div style={{ marginTop: 20, marginBottom: 10 }}>
        <TextArea
          label="Поле для данных диаграммы"
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            handleChange(event);
            setData(event.target.value);
          }}
          name={fieldName("raw_data")}
          value={blockValue?.raw_data}
          isValid={!error}
          validationMessage={error}
          rows={25}
        />
      </div>
    </div>
  );
}
