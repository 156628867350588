import React, { useCallback } from "react";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import Feature from "ol/Feature";
import { RLayerVector, RFeature, RStyle, RPopup } from "rlayers";
import { Layer } from "../../config/interfaces";
import cities from "../../icons/rectangle.svg";
import styles from "../../Map.module.css";
import { getColorByScale } from "../../utils/get_color_by_scale";
import PopUp from "../../../PopUp/PopUp";
import { roundToDecimal } from "../../../../common/utils";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function Turizm(layers: AtlasLayerInterface) {
  // layer1
  const layer = layers.layers[0];
  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=100&outputFormat=application%2Fjson`;

  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });
  type inputDataType = {
    records: { fields: { name_rus: string; color_hex: string } }[];
  };
  const fetchData = fetch(layer_url).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ records: [] } as inputDataType);
  const [current, setCurrent] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);

  // layer 2
  const layer2 = layers.layers[1];
  const layer_url2 = `https://geo.touristatlas.ru/geoserver/${layer2.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer2.workspace}%3A${layer2.layer}&maxFeatures=100&outputFormat=application%2Fjson`;

  const parser2 = new GeoJSON({ featureProjection: "EPSG:4326" });

  const fetchData2 = fetch(layer_url2).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data2, setData2] = React.useState({ records: [] } as inputDataType);
  const [current2, setCurrent2] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData2.then((r) => setData2(r));
  }, []);

  const getIcon = (f: any) => {
    let color = f.values_.color_hex.trim();
    return <RStyle.RIcon src={cities} color={color} />;
  };

  let classified_prop = "year_2020";
  if (layer2.classified_prop) {
    classified_prop = layer2.classified_prop;
  }

  const renderStyle = useCallback(
    (f: any) => {
      let color = "#ffffff";
      if (layer2.scale) {
        color = getColorByScale(f, classified_prop, layer2.scale, "#ffffff");
      }
      return (
        <>
          <RStyle.RFill color={color} />
          <RStyle.RStroke width={0.5} color={"#f5f5f5"} />
        </>
      );
    },
    [classified_prop]
  );

  return (
    <>
      <RLayerVector
        zIndex={2}
        format={parser}
        url={layer_url}
        onPointerEnter={useCallback((e: any) => setCurrent(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current === e.target && setCurrent(null),
          [current]
        )}
      >
        <RStyle.RStyle render={useCallback((f: any) => getIcon(f), [data])} />
      </RLayerVector>

      <RLayerVector
        zIndex={1}
        format={parser2}
        url={layer_url2}
        onPointerEnter={useCallback((e: any) => setCurrent2(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current === e.target && setCurrent2(null),
          [current]
        )}
      >
        <RStyle.RStyle render={renderStyle} />
      </RLayerVector>

      {layer.label ? (
        <RLayerVector zIndex={3}>
          {current ? (
            <div>
              <RFeature geometry={current.getGeometry()}>
                <RPopup
                  trigger={"hover"}
                  className={styles.customPopup}
                  autoPosition={true}
                >
                  <div className={styles.plashkaMountains}>
                    <div className={styles.mountainName}>
                      {current.get("name")}
                    </div>
                  </div>
                </RPopup>
              </RFeature>
            </div>
          ) : null}
        </RLayerVector>
      ) : null}

      {layer.label ? (
        <RLayerVector zIndex={3}>
          {current2 ? (
            <div>
              <RFeature geometry={current2.getGeometry()}>
                <RPopup
                  trigger={"hover"}
                  className={styles.customPopup}
                  autoPosition={true}
                >
                  <PopUp
                    popupData={{
                      title: current2.get("name_rus"),
                      subTitle: "Выручка в млрд. руб.",
                      barChart: {
                        legends: ["2019", "2020", "2021"],
                        datasets: [
                          {
                            data: [
                              roundToDecimal(
                                current2.get("year_2019") / 1000,
                                2
                              ),
                              roundToDecimal(
                                current2.get("year_2020") / 1000,
                                2
                              ),
                              roundToDecimal(
                                current2.get("year_2021") / 1000,
                                2
                              ),
                            ],
                            color: ["#d5434f", "#d5434f", "#d5434f"],
                          },
                        ],
                        is_tooltip_enabled: false,
                      },
                    }}
                  />
                </RPopup>
              </RFeature>
            </div>
          ) : null}
        </RLayerVector>
      ) : null}
    </>
  );
}
