import {
  OAuthToken,
  isTokenExpired,
  refreshToken,
} from "./../auth/tokenService";
import { getTokensFromStorage } from "../auth/tokenService";
import { AtlasMap } from "../components/Map/config/interfaces";
import { Post, MenuItem } from "../types/content-types";
import { AbstractLayer } from "../pages/ConstructorPage";

let isTokenRefreshing = false;
let tokenRefreshPromise: any = null;
const nonAuthCode = [400, 401];

export const WEBSITE_URL = "https://touristatlas.ru";

export function createOptionsWithToken(opts: any, token: OAuthToken | null) {
  return {
    ...opts,
    headers: {
      ...opts?.headers,
      Authorization: `Bearer ${token?.access_token}`,
    },
  };
}

async function interceptFetch(url: string, opts: any = {}): Promise<Response> {
  let response = await fetch(url, opts);

  let token = getTokensFromStorage();

  if (nonAuthCode.includes(response.status) || isTokenExpired(token)) {
    if (isTokenRefreshing) {
      token = await tokenRefreshPromise;
      opts = createOptionsWithToken(opts, token);
      return fetch(url, opts);
    }

    isTokenRefreshing = true;

    tokenRefreshPromise = refreshToken()
      .then((newToken) => {
        opts.headers.Authorization = `Bearer ${newToken?.access_token}`;
        return newToken;
      })
      .finally(() => {
        isTokenRefreshing = false;
        tokenRefreshPromise = null;
      });

    token = await tokenRefreshPromise;

    opts.headers.Authorization = `Bearer ${token.access_token}`;
    response = await fetch(url, opts);
  }

  return response;
}

function insertDemoSegment(originalString: string) {
  return originalString.replace(/\/api\/(\w+)/, "/api/demo/$1");
}

export async function makeRequest<T>(
  url: string,
  opts?: RequestInit,
  noDemo?: boolean
): Promise<T> {
  const token = getTokensFromStorage();

  let resp;
  if (!token) {
    resp = await fetch(noDemo ? url : insertDemoSegment(url), opts);
  } else {
    const options = createOptionsWithToken(opts, token);
    resp = await interceptFetch(url, options);
  }

  if (resp.ok) {
    const contentLength = resp.headers.get("content-length");
    if (contentLength === "0") {
      return { success: true } as any;
    }
    return resp.json();
  } else {
    const message = await resp.json();

    throw new Error(message.Message);
  }
}

export async function getPostById(postId: number): Promise<Post> {
  return await makeRequest<Post>(`/api/posts/get?id=${postId}`);
}

export async function getMap(mapId: number): Promise<AtlasMap> {
  return await makeRequest<AtlasMap>(`/api/maps/get?id=${mapId}`);
}

export async function getConstructorBaseMap(
  cluster_id: number
): Promise<AtlasMap> {
  return await makeRequest<AtlasMap>(
    `/api/maps/builder/basemap?cluster_id=${cluster_id}`
  );
}

export async function getConstructorLayers() {
  return await makeRequest<AbstractLayer[]>(
    "/api/maps/builder/layers?cluster_id=2"
  );
}

export async function getMapsList(): Promise<MenuItem[]> {
  return await makeRequest<MenuItem[]>(`/api/maps/list`);
}

export async function getPosts(opts: {
  clusterId?: number;
  chapterId?: number;
}): Promise<MenuItem[]> {
  const clusterString = opts.clusterId ? `cluster_id=${opts.clusterId}` : "";
  const chapterString = opts.chapterId ? `&chapter_id=${opts.chapterId}` : "";
  return await makeRequest<MenuItem[]>(
    `/api/posts/get/linked?${clusterString}${chapterString}`
  );
}

export async function getChaptersAndClusters(): Promise<{
  chapters: MenuItem[];
  clusters: MenuItem[];
}> {
  const chapters = await makeRequest<MenuItem[]>(`/api/chapters/list`);
  const clusters = await makeRequest<MenuItem[]>(`/api/clusters/list`);
  return { clusters, chapters };
}

export async function createPost(contentData: Post) {
  return await makeRequest<Post>(`/api/posts/create`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(contentData),
  });
}

export async function updatePost(contentData: Post) {
  return await makeRequest<Post>(`/api/posts/update`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("atlas_token")}`,
    },
    body: JSON.stringify(contentData),
  });
}

export async function deletePost(postId: number) {
  return await makeRequest<Post>(`/api/posts/delete?id=${postId}`, {
    method: "post",
  });
}

export async function createMap(contentData: AtlasMap) {
  return await makeRequest<AtlasMap>(`/api/maps/create`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(contentData),
  });
}

export async function updateMap(contentData: string) {
  return await makeRequest<AtlasMap>(`/api/maps/update`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(contentData),
  });
}

export async function deleteMap(mapId: number) {
  return await makeRequest<AtlasMap>(`/api/maps/delete?id=${mapId}`, {
    method: "post",
  });
}

export async function verifyEmailWithCode(code: string) {
  return await makeRequest<{}>(
    `/api/users/verify/sign-up/code`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ Token: code }),
    },
    true
  );
}

export async function sendCodeOnEmail(email: string) {
  return await makeRequest<{}>(
    `/api/users/verify/sign-up/send`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    },
    true
  );
}

export async function sendPassRestoreEmail(email: string) {
  return await makeRequest<{}>(
    `/api/users/verify/password-restore/send`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    },
    true
  );
}

export async function sendNewPass({
  password,
  token,
}: {
  password: string;
  token: string;
}) {
  return await makeRequest<{}>(
    `/api/users/restore/password`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password, token }),
    },
    true
  );
}
