import { AtlasMap } from "./interfaces";

export const turizm_rus: AtlasMap = {
  basemap:
    "https://geo.touristatlas.ru/geoserver/base/wms?service=WMS&version=1.1.0&request=GetMap&layers=base%3Abase&bbox=-180.0%2C-90.0%2C180.0%2C90.0&width=768&height=384&srs=EPSG%3A4326&styles=&format=application%2Fopenlayers2",
  projection: "EPSG:3576",
  params: { background: "0xD3D5DA", transparent: true },
  zoom: { min: 0, max: 6, start: 4 },
  center: [23.882598, -20.528082],
  extent: { xmin: 0, ymin: 0, xmax: 0, ymax: 6 },
  layers: [
    {
      layer: "c3_msk_spb",
      workspace: "base",
      label: "name",
      geometry_type: "points",
    },
    {
      layer: "c3_turizm_rus_sub",
      workspace: "base",
      label: "name_rus",
      geometry_type: "polygons",
    },
  ],
};
