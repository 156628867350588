import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getTokensFromStorage } from "./auth/tokenService";

export const PrivateRoute = () => {
  const tokens = getTokensFromStorage();

  const location = useLocation();

  return tokens ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
