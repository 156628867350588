import React, { useCallback } from "react";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import Feature from "ol/Feature";
import { RLayerVector, RFeature, RStyle, RPopup } from "rlayers";
import { Layer } from "../../config/interfaces";
import cities from "../../icons/rectangle.svg";
import styles from "../../Map.module.css";
import BarInfoWindow from "../../elements/diagrams/Bar";
import {getColorByScale} from "../../utils/get_color_by_scale";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function YufuPopulation(layers: AtlasLayerInterface) {

  type inputDataType = {
    records: { fields: { name_rus: string, color_hex: string } }[];
  };

  // layer 2
  const layer2 = layers.layers[0];
  const layer_url2 = `https://geo.touristatlas.ru/geoserver/${layer2.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer2.workspace}%3A${layer2.layer}&maxFeatures=500&outputFormat=application%2Fjson`;
  
  const parser2 = new GeoJSON({ featureProjection: "EPSG:4326" });

  const fetchData2 = fetch(layer_url2).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data2, setData2] = React.useState({ records: [] } as inputDataType);
  const [current2, setCurrent2] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData2.then((r) => setData2(r));
  }, []);

  const getIcon = (f: any) => {
    let color = f.values_.color_hex.trim();
    return (<RStyle.RIcon src={cities} color={color}/>);   
  }

  let classified_prop = "year_2020"
  if (layer2.classified_prop) {
    classified_prop = layer2.classified_prop;
  };
  
  // a6caff 6ca2ff 2861f7  0039ce 0028ac
  const renderStyle = useCallback((f: any) => {
    let color = "#ffffff";
    if (layer2.scale) {
      color = getColorByScale(f, classified_prop, layer2.scale, "#ffffff");
    }
    return (
      <>
      <RStyle.RFill color={color}/>
      <RStyle.RStroke width={0.5} color={'#f5f5f5'}/>
      </>
      ); 
  }, [classified_prop])

  return (
    <>
      <RLayerVector
        zIndex={1}
        format={parser2}
        url={layer_url2}
        onPointerEnter={useCallback((e: any) => setCurrent2(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current2 === e.target && setCurrent2(null),
          [current2]
        )}
      >
        <RStyle.RStyle
          render={renderStyle}
        />
      </RLayerVector>

      { layer2.label ? 
      <RLayerVector zIndex={3}>
        {current2 ? (
          <div>
            <RFeature geometry={current2.getGeometry()}>
              <RPopup trigger={"hover"} className={styles.customPopup} autoPosition={true}>
              <div className={styles.framePopupDiagram}>
              <BarInfoWindow 
                title={current2.get('name')} 
                values={[current2.get('year_2012') / 1000, current2.get('year_2017') / 1000, current2.get('year_2023') / 1000]} 
                labels={['2012', '2017', '2023']}
                barColor={'#0039ce'}/>
              </div>
              </RPopup>
            </RFeature>
          </div>
        ) : null}
      </RLayerVector> : null }
    </>
  );
}
