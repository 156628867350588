import { CurrentAtlasMap } from "./interfaces";
import { clusters } from "./Clusters";
import { airlines } from "./Airlines";
import { turizm_rus } from "./Turizm_Rus";
import { default_map_config } from "./default_map_config";

export const maps: CurrentAtlasMap[] = [
  { id: "uuid1", map_config: default_map_config },
  { id: "uuid1", map_config: clusters },
  { id: "uuid2", map_config: airlines },
  { id: "uuid3", map_config: turizm_rus },
];
