import React, { useCallback } from "react";
import GeoJSON from "ol/format/GeoJSON";
import { RLayerVector, RFeature, RStyle, ROverlay } from "rlayers";
import { Layer } from "../../config/interfaces";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import { 
  SimpleRectangle, 
  SimpleRectangleSelectedInfoWindow 
} from "../../elements/diagrams";
import { useSelector} from "react-redux";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function ReligionPravoslavnye(layers: AtlasLayerInterface) {
  // layer1
  const layer = layers.layers[0];
  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=100&outputFormat=application%2Fjson`;

  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });
  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { value: number, church: string, descrptn: string } }[];
  };
  const fetchData = fetch(layer_url).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ features: [] } as inputDataType);
  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);

  // layer 3
  const layer3 = layers.layers[1];
  const layer_url3 = `https://geo.touristatlas.ru/geoserver/${layer3.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer3.workspace}%3A${layer3.layer}&maxFeatures=1000&outputFormat=application%2Fjson`;
  
  const parser3 = new GeoJSON({ featureProjection: "EPSG:4326" });

  const fetchData3 = fetch(layer_url3).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data3, setData3] = React.useState({ features: [] } as inputDataType);
  React.useEffect(() => {
    fetchData3.then((r) => setData3(r));
  }, []);

  const getLineColor = (f: any) => {
    let color = "#000000"
    if (layer3.color) {
      color = layer3.color;
    }
    return (<RStyle.RStroke width={2} color={color}/>); 
  }

  const isSelected = useSelector((state: any) => state.selectFeature.isSelected);
  const selectedFeature = useSelector((state: any) => state.selectFeature.feature);

  return (
    <>
    <RLayerVector
        zIndex={2}
        format={parser}
        url={layer_url}
        visible={false}
      >
        {isSelected ?
            <RFeature geometry={
              new Point(fromLonLat([
                selectedFeature.geometry.coordinates[0], 
                selectedFeature.geometry.coordinates[1]
                ]))}>
            <ROverlay 
            positioning="top-left">
              <SimpleRectangleSelectedInfoWindow 
                value={selectedFeature.id.split('.')[1]} 
                width={24} height={24} 
                color={'#ffffff'} 
                background={"#D65A68"}
                minValue={24}
                label={selectedFeature.properties.church}
                text={selectedFeature.properties.descrptn}/>
           </ROverlay>
          </RFeature>
          : 
          <RFeature geometry={new Point(fromLonLat([0, 0]))}>
            <ROverlay positioning="center-left"></ROverlay>
          </RFeature>
           }
        {data.features.map((f) => (
        <RFeature 
          key={f.id}
          geometry={new Point(fromLonLat(f.geometry.coordinates))}
          properties={f.properties}
          >
          <ROverlay 
          positioning="top-left">
              <SimpleRectangle 
                feature={f}
                value={Number(f.id.split('.')[1])} 
                width={24} height={24} 
                color={'#ffffff'} 
                background={"#D65A68"}
                minValue={0}
                label={f.properties.church}/>
           </ROverlay>
          </RFeature>
        ))}
      </RLayerVector>
      <RLayerVector
        zIndex={1}
        format={parser3}
        url={layer_url3}
      >
        <RStyle.RStyle
          render={useCallback(
            (f: any) => (getLineColor(f)),
            [data3]
          )}
        />
      </RLayerVector>
    </>
  );
}
