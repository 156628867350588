import React from "react";
import styles from "./MapControls.module.scss";

export type ZoomHomeButtonProps = { onClick: () => void };

const ZoomHomeButton: React.FC<ZoomHomeButtonProps> = ({ onClick }) => {
  return <div className={styles.zoomHomeButton} onClick={onClick} />;
};

export default ZoomHomeButton;
