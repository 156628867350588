import React, { useEffect } from "react";
import styles from "./WelcomeWindow.module.scss";
import { useNavigate } from "react-router-dom";
import { getTokensFromStorage } from "../../auth/tokenService";

export type WelcomeWindowProps = {};

const WelcomeWindow: React.FC<WelcomeWindowProps> = () => {
  const tokens = getTokensFromStorage();
  const navigate = useNavigate();

  useEffect(() => {
    if (tokens) {
      navigate("/map");
    }
  }, [tokens, navigate]);

  const handleAuthorizeClick = () => {
    navigate("/login");
  };

  const handleMapClick = () => {
    navigate("/map");
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>ТУРИСТСКИЙ АТЛАС</div>
          <div className={styles.subTitle}>
            Путеводитель инвестора и руководителя
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.button} onClick={handleAuthorizeClick}>
            <div className={styles.titleButton}>Вход</div>
          </div>
          <div className={styles.buttonDemo} onClick={handleMapClick}>
            <div className={styles.titleButtonDemo}>Демо</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeWindow;
