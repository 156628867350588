export function genFieldName(index: number, name: string) {
  return `content_blocks.${index}.${String(name)}`;
}

export function genNestedFieldName(
  index: number,
  innerIndex: number,
  name: string,
) {
  return `content_blocks.${index}.nested.${innerIndex}.${String(name)}`;
}
