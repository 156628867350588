import React from "react";
import styles from "./Auth.module.scss";
import { ModuleProps } from "./WindowAuth";
import useEnterKeyListener from "../../hooks/useEnterKeyListener";

export default function SuccessRecoveryModule({
  setStep,
  credentials,
}: ModuleProps) {
  const handleKeyDown = () => {};

  const handleKeyUp = () => {
    setStep("mailcheck");
  };

  useEnterKeyListener(handleKeyDown, handleKeyUp);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.authModule}>
        <div className={styles.title}>Изменение пароля</div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldTitle}>
            На электронную почту <b>{credentials.email}</b> отправлена
            инструкция и ссылка на страницу сброса пароля.
          </div>
        </div>
        <div className={styles.flexCenterContainer}>
          <div
            className={styles.footerLink}
            onClick={() => {
              setStep("mailcheck");
            }}
          >
            Вернуться на страницу авторизации
          </div>
        </div>
      </div>
    </div>
  );
}
