import React, { useCallback } from "react";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import Feature from "ol/Feature";
import { RLayerVector, RFeature, RStyle, ROverlay, RPopup } from "rlayers";
import { Layer } from "../../config/interfaces";
import cities from "../../icons/rectangle.svg";
import styles from "../../Map.module.css";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function VITIGTurizm(layers: AtlasLayerInterface) {
  // layer1
  const layer = layers.layers[0];
  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });
  type inputDataType = {
    records: { fields: { name: string, type: string } }[];
  };
  const fetchData = fetch(layer_url).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ records: [] } as inputDataType);
  const [current, setCurrent] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);

  // layer 2
  const layer2 = layers.layers[1];
  const layer_url2 = `https://geo.touristatlas.ru/geoserver/${layer2.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer2.workspace}%3A${layer2.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser2 = new GeoJSON({ featureProjection: "EPSG:4326" });

  const fetchData2 = fetch(layer_url2).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data2, setData2] = React.useState({ records: [] } as inputDataType);
  const [current2, setCurrent2] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData2.then((r) => setData2(r));
  }, []);

  // layer 3
  const layer3 = layers.layers[2];
  const layer_url3 = `https://geo.touristatlas.ru/geoserver/${layer3.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer3.workspace}%3A${layer3.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser3 = new GeoJSON({ featureProjection: "EPSG:4326" });

  const fetchData3 = fetch(layer_url3).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data3, setData3] = React.useState({ records: [] } as inputDataType);
  const [current3, setCurrent3] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData3.then((r) => setData3(r));
  }, []);

  // layer 3
  const layer4 = layers.layers[3];
  const layer_url4 = `https://geo.touristatlas.ru/geoserver/${layer4.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer4.workspace}%3A${layer4.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser4 = new GeoJSON({ featureProjection: "EPSG:4326" });

  const fetchData4 = fetch(layer_url4).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data4, setData4] = React.useState({ records: [] } as inputDataType);
  const [current4, setCurrent4] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData4.then((r) => setData4(r));
  }, []);


  const getIcon = (f: any) => {
    let color = "#000000";
    return (<RStyle.RIcon src={cities} color={color}/>);   
  }

  const getLineColor = (f: any) => {
    let color = f.values_.color_hex;
    if (!color) {
        color = "#7F7F7F";
    }
    else {
        color = f.values_.color_hex.trim();
    }
    let width = 6;
    let width_props = f.values_.width;
    if (width_props) {
        width = width_props;
    }
    return (<RStyle.RStroke width={width} color={color}/>); 
  }

  const getPolygonColor = (f: any) => {
    let color = f.values_.color_hex;
    if (!color) {
        color = "#e7e7e7";
    }
    else {
        color = f.values_.color_hex.trim();
    }
    return (<>
    <RStyle.RFill color={color}/>
    <RStyle.RStroke width={0.5} color={"#373737"}/>
    </>
    ); 
  }

  return (
    <>
      <RLayerVector
        zIndex={4}
        format={parser}
        url={layer_url}
        onPointerEnter={useCallback((e: any) => setCurrent(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current === e.target && setCurrent(null),
          [current]
        )}
      >
        <RStyle.RStyle
          render={useCallback(
            (f: any) => (getIcon(f)),
            [data]
          )}
        />
      </RLayerVector>

      <RLayerVector
        zIndex={3}
        format={parser2}
        url={layer_url2}
        onPointerEnter={useCallback((e: any) => setCurrent2(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current === e.target && setCurrent2(null),
          [current2]
        )}
      >
        <RStyle.RStyle
          render={useCallback(
            (f: any) => (getIcon(f)),
            [data2]
          )}
        />
      </RLayerVector>

      <RLayerVector
        zIndex={2}
        format={parser3}
        url={layer_url3}
      >
        <RStyle.RStyle
          render={useCallback(
            (f: any) => (getLineColor(f)),
            [data3]
          )}
        />
      </RLayerVector>

      <RLayerVector
        zIndex={1}
        format={parser4}
        url={layer_url4}
      >
        <RStyle.RStyle
          render={useCallback(
            (f: any) => (getPolygonColor(f)),
            [data3]
          )}
        />
      </RLayerVector>

      { layer.label ? 
      <RLayerVector zIndex={3}>
        {current ? (
          <div>
            <RFeature geometry={current.getGeometry()}>
              <RPopup trigger={"hover"} className={styles.customPopup} autoPosition={true}>
                <div className={styles.plashkaMountains}>
                    <div className={styles.mountainName}>{current.get('name')}</div>
                </div>
              </RPopup>
            </RFeature>
          </div>
        ) : null}
      </RLayerVector> : null }

      { layer.label ? 
      <RLayerVector zIndex={3}>
        {current2 ? (
          <div>
            <RFeature geometry={current2.getGeometry()}>
              <RPopup trigger={"hover"} className={styles.customPopup} autoPosition={true}>
                <div className={styles.plashkaMountains}>
                    <div className={styles.mountainName}>{current2.get('name')}</div>
                </div>
              </RPopup>
            </RFeature>
          </div>
        ) : null}
      </RLayerVector> : null }
    </>
  );
}
