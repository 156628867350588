import { Style, Fill, Stroke, RegularShape } from 'ol/style';
import { FeatureLike } from 'ol/Feature';

interface FeatureStyleProps {
  feature: FeatureLike;
  isHovered: boolean;
  fillColor: string;
  outlineColor: string;
}

// Функция, возвращающая стиль
const createFeatureStyle = ({ feature, isHovered, fillColor, outlineColor }: FeatureStyleProps) => {
  return new Style({
    image: new RegularShape({
      fill: new Fill({
        color: isHovered ? '#00aaff' : fillColor,
      }),
      stroke: new Stroke({
        color: outlineColor,
        width: isHovered ? 0 : 1, // Убираем обводку при наведении
      }),
      points: 4,
      radius: 12,
      angle: Math.PI / 4, // Поворот на 45 градусов
    }),
  });
};

export default createFeatureStyle;