import React, { useEffect } from "react";
import { TextField } from "../Components";
import { SubFormProps } from "../types";
import { Post, Scale } from "../../types/content-types";
import { useFormikContext } from "formik";
import styles from "../Components.module.scss";
import Button from "../../components/Button/Button";
import ViewContainer from "../../components/ViewContainer/ViewContainer";
import { useEditorField } from "./useEditorField";
import SolidScale from "../../components/Charts/SolidScale/SolidScale";

export default function SolidScaleForm({
  index,
  nestedIndex = 0,
  isNested = false,
}: SubFormProps) {
  const { setFieldValue, handleChange } = useFormikContext<Post>();

  function initScale(): Scale {
    return {
      data: [
        { value: 0, color: "" },
        { value: 0, color: "" },
      ],
      legend: [{ title: "", color: "" }],
      sub_type: "solid",
    };
  }

  type ScaleDataItem = {
    value: number;
    color: string;
  };

  type ScaleLegendItem = {
    title: string;
    color: string;
  };

  function createScaleDataItem(): ScaleDataItem {
    return {
      value: 0,
      color: "",
    };
  }

  function createScaleLegendItem(): ScaleLegendItem {
    return {
      title: "",
      color: "",
    };
  }

  const name = "scale";

  const { fieldName, blockValue } = useEditorField({
    index,
    nestedIndex,
    isNested,
  });

  const addElement = () => {
    const { scale } = blockValue || {};
    if (scale && scale.data && scale.legend) {
      const updatedScale = { ...scale };
      updatedScale.data.push(createScaleDataItem());
      updatedScale.legend.push(createScaleLegendItem());
      setFieldValue(fieldName(name), updatedScale);
    }
  };

  const deleteElement = (deleteIndex: number) => {
    const { scale } = blockValue || {};
    if (scale && scale.data && scale.legend) {
      const updatedScale = { ...scale };
      const filteredData = updatedScale.data.filter(
        (_, index) => index !== deleteIndex + 1
      );
      const filteredLegend = updatedScale.legend.filter(
        (_, index) => index !== deleteIndex
      );
      updatedScale.data = filteredData;
      updatedScale.legend = filteredLegend;
      setFieldValue(fieldName(name), updatedScale);
    }
  };

  useEffect(() => {
    if (blockValue?.scale?.data && blockValue?.scale?.data.length === 0) {
      setFieldValue(fieldName(name), initScale());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const elementCount = blockValue?.scale?.legend.length ?? 0;

  return (
    <>
      <ViewContainer>
        {blockValue && <SolidScale contentBlock={blockValue} />}
      </ViewContainer>
      {blockValue?.scale?.legend.map((element, index) => (
        <div key={index} className={styles.elementContainer}>
          {index === 0 && (
            <TextField
              label={`Цвет ячейки №${
                index + 1
              } в формате "#000000" или "rgb(255,255,255)"`}
              onChange={handleChange}
              value={blockValue?.scale?.data[index].color}
              name={`${fieldName(name)}.data.[${index}].color`}
            />
          )}
          <TextField
            label={`Цвет ячейки №${
              index + 2
            } в формате "#000000" или "rgb(255,255,255)"`}
            onChange={handleChange}
            value={blockValue?.scale?.data[index + 1].color}
            name={`${fieldName(name)}.data.[${index + 1}].color`}
          />
          <TextField
            label="Подпись элемента шкалы"
            onChange={handleChange}
            value={element.title}
            name={`${fieldName(name)}.legend.[${index}].title`}
          />
          {index !== 0 && (
            <div className={styles.containerRight}>
              <Button
                title="Х"
                onClick={() => {
                  deleteElement(index);
                }}
              />
            </div>
          )}
        </div>
      ))}
      {elementCount < 6 && (
        <div className={styles.containerCenter}>
          <Button type="button" onClick={addElement} title="Добавить элемент" />
        </div>
      )}
    </>
  );
}
