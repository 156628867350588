import React from "react";
import styles from "./TextComponents.module.scss";

export default function Paragraph({ text }: { text: string }) {
  const paragraphs = text.split("\n\n");
  const textWithIndentation = paragraphs.map((paragraph, index) => (
    //можно указать отступ с края у параграфа
    <p key={index} style={{ textIndent: 0 }}>
      {paragraph}
    </p>
  ));
  return <div className={styles.paragraph}>{textWithIndentation}</div>;
}
