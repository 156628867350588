import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartEvent,
  ActiveElement,
  Chart,
  TooltipItem,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useState } from "react";
import { findLargestNumberAmongArrays } from "../../../common/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

type DatasetChart = {
  label?: string;
  /** Значения элементов диаграммы */
  data: number[];
  /** Цвета элементов диаграммы */
  color: string[];
  /** Тексты подсказки элементов диаграммы */
  tooltip?: string[];
};

export type BarChartProps = {
  /** Заголовок диаграммы */
  title?: string;
  /** Включает горизонтальный вид диаграммы */
  is_horizontal?: boolean;
  /** Подпись внизу столба или группы столбов и в подсказке */
  legends: string[];
  datasets: DatasetChart[];
  /** Включение функции подсветки выделенного элемента' */
  /** default: true */
  is_hover_active?: boolean;
  /** Максимально значение шкалы */
  max_scale_value?: number;
  /** Значение задает отношение ширины к высоте диаграммы */
  /** default: 1 */
  aspect?: number;
  /** Значение увеличивает свободное пространство между стобами и краем диаграмммы */
  /** по-умолчанию высчитывается 10% от макс значения для вертикальной и 20% для горизонтальной */
  grace?: number;
  /** Значение ширины столба */
  /** default: 0.7 */
  bar_percentage?: number;
  /** Отключение функции отображения значений над столбами */
  is_bar_labels_disabled?: boolean;
  /** Размер шрифта значения над столбами */
  /** default: 18 */
  bar_labels_font_size?: number;
  /** Размер шрифта легенды под столбами */
  /** default: 14 */
  legend_font_size?: number;
  /** Добавляется подпись для значений над столбами */
  data_labels_description?: string;
  /** Делает значения над столбами одного цвета (gray50) */
  is_default_bar_labels_color?: boolean;
  /** Включение подсказки */
  /** default: true */
  is_tooltip_enabled?: boolean;
  /** Включение лейблов */
  /** default: false */
  is_labels_enabled?: boolean;
  /** Положение лейблов над диаграммой */
  /** default: center */
  labels_position?: "start" | "center" | "end";
};

export default function BarChart({
  title,
  is_horizontal,
  legends,
  datasets,
  is_hover_active = true,
  max_scale_value,
  aspect,
  grace,
  bar_percentage,
  is_bar_labels_disabled,
  bar_labels_font_size,
  legend_font_size,
  data_labels_description,
  is_default_bar_labels_color,
  is_tooltip_enabled = true,
  is_labels_enabled,
  labels_position,
}: BarChartProps) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const fontFamily = "Nunito Sans";
  const groupedBars = datasets.length > 1;

  const preparingColors = (colors: string[], arrIndex: number): string[] => {
    return is_hover_active
      ? activeIndex === null
        ? colors
        : groupedBars
        ? colors.map((color) => (activeIndex === arrIndex ? color : "#f0f1f5"))
        : colors.map((color, index) =>
            activeIndex === index ? color : "#f0f1f5"
          )
      : colors;
  };

  const configuredGrace =
    is_bar_labels_disabled || datasets.length === 0 || max_scale_value
      ? 0
      : Math.ceil(findLargestNumberAmongArrays(datasets));

  const preparedDatasets = datasets.map((dataset, index) => {
    return {
      label: dataset.label ? dataset.label : `${index + 1}`,
      data: dataset.data,
      backgroundColor: preparingColors(dataset.color, index),
    };
  });

  const data = {
    labels: legends,
    datasets: preparedDatasets,
  };

  const options = {
    responsive: true,
    indexAxis: is_horizontal ? ("y" as const) : ("x" as const),
    aspectRatio: aspect ? aspect : 1,
    animation: {
      duration: 700,
    },
    barPercentage: bar_percentage ? bar_percentage : 0.7,
    scales: {
      x: {
        max: is_horizontal ? max_scale_value : undefined,
        grid: {
          display: is_horizontal ? true : false,
        },
        grace: is_horizontal
          ? grace
            ? grace
            : configuredGrace * 0.2
          : undefined,
        ticks: {
          font: {
            size: !is_horizontal ? legend_font_size : 12,
            family: fontFamily,
            weight: !is_horizontal ? "bold" : "normal",
          },
        },
      },
      y: {
        max: !is_horizontal ? max_scale_value : undefined,
        grid: {
          display: !is_horizontal ? true : false,
        },
        grace: !is_horizontal
          ? grace
            ? grace
            : configuredGrace * 0.1
          : undefined,
        ticks: {
          font: {
            size: is_horizontal ? legend_font_size : 12,
            family: fontFamily,
            weight: is_horizontal ? "bold" : "normal",
          },
        },
      },
    },
    plugins: {
      title: {
        display: !!title,
        text: title,
        font: {
          size: 18,
          family: fontFamily,
        },
      },
      legend: {
        display: is_labels_enabled ? true : false,
        align: labels_position,
        labels: {
          font: {
            size: 15,
            family: fontFamily,
            weight: "bold",
          },
          color: "#272d34",
          boxWidth: 18,
          boxHeight: 18,
        },
      },
      tooltip: {
        enabled: is_tooltip_enabled,
        position: "nearest" as const,
        yAlign: is_horizontal ? ("center" as const) : ("top" as const),
        backgroundColor: "#fff",
        titleFont: {
          size: 14,
          family: fontFamily,
        },
        bodyFont: {
          size: 12,
          family: fontFamily,
        },
        titleColor: "#272d34",
        bodyColor: "#272d34",
        titleAlign: "center" as const,
        bodyAlign: "center" as const,
        borderColor: "#bcbfcc",
        borderWidth: 1,
        displayColors: false,
        callbacks: {
          label: (tooltipItem: TooltipItem<"bar">) => {
            const tooltips = datasets[tooltipItem.datasetIndex].tooltip;
            const value = tooltips ? tooltips[tooltipItem.dataIndex] : "";
            return value ? value : "";
          },
        },
      },
      datalabels: {
        display: is_bar_labels_disabled ? false : true,
        anchor: "end" as const,
        align: is_horizontal ? ("right" as const) : ("top" as const),
        formatter: (value: number) =>
          data_labels_description
            ? `${value}${data_labels_description}`
            : value,
        color: (context: any) => {
          return is_default_bar_labels_color
            ? context.dataset.backgroundColor.map((color: string) =>
                color !== "#f0f1f5" ? "#272d34" : color
              )
            : context.dataset.backgroundColor;
        },
        font: {
          size: bar_labels_font_size ?? (18 as const),
          family: fontFamily,
          weight: "bold" as const,
        },
      },
    },
    onHover: is_hover_active
      ? (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
          if (elements.length) {
            const index = groupedBars
              ? elements[0].datasetIndex
              : elements[0].index;
            setActiveIndex(index);
          } else {
            setActiveIndex(null);
          }
        }
      : undefined,
  };

  return <Bar data={data} options={options} />;
}
