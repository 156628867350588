import React, { useRef } from "react";

import styles from "./ModalWindow.module.scss";

import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";

import { close as modalClose } from "../../redux/modal";
import Button from "../Button/Button";
import ImageBlock from "../ImageBlock/ImageBlock";

export default function ModalWindow({
  ...restProps
}: React.ComponentProps<"div">) {
  const modalRef = useRef(null);
  const dispatch = useDispatch<AppDispatch>();

  const { isVisible, content, title, subtitle, image } = useSelector(
    (state: RootState) => state.modalWindow
  );
  const handleCloseModal = () => {
    dispatch(modalClose());
  };

  const element = isVisible ? (
    <div
      className={styles.backdrop}
      onClick={(event) => event.stopPropagation()}
    >
      <div {...restProps} className={styles.modalWindow} ref={modalRef}>
        <div className={styles.scrollContainer}>
          <div className={styles.contantContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>{title}</div>
              <button
                className={styles.closeButton}
                onClick={handleCloseModal}
              ></button>
            </div>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
            {content && <p className={styles.paragraph}>{content}</p>}
            {image && <ImageBlock contentBlock={image} key={1} />}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                title="Закрыть"
                style={{ marginTop: 8 }}
                onClick={handleCloseModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  return createPortal(element, document.getElementById("root")!);
}
