import { useState } from "react";
import styles from "./TextField.module.scss";
import classNames from "classnames";
import baseStyles from "../inputBase.module.scss";

export type TextFieldProps = {
  isValid?: boolean;
  validationMessage?: string;
  disabled?: boolean;
};

export const TextField = ({
  type = "text",
  isValid = true,
  validationMessage,
  disabled,
  ...props
}: TextFieldProps & React.AllHTMLAttributes<HTMLInputElement>) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const isPassword = type === "password";

  const inputType =
    type === "password" ? (passwordVisible ? "text" : "password") : type;

  return (
    <div className={styles.inputContainer}>
      <input
        type={inputType}
        className={classNames(baseStyles.inputBase, {
          [styles.notValid]: isValid === false,
          [styles.disabled]: disabled === true,
        })}
        disabled={disabled}
        {...props}
      />
      {isPassword && (
        <button
          className={classNames(styles.eyeIcon, {
            [styles.eyeIconClose]: passwordVisible === false,
          })}
          onClick={() => {
            setPasswordVisible((prev) => !prev);
          }}
        ></button>
      )}
      {isValid === false && validationMessage && (
        <div className={styles.validationMessage}>{validationMessage}</div>
      )}
    </div>
  );
};
