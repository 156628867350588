import GeoJSON from "ol/format/GeoJSON";
import Feature from "ol/Feature";
import { Geometry } from "ol/geom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RFeature, RLayerVector, RStyle, RPopup } from "rlayers";
import { Layer } from "../../config/interfaces";
import { CustomInfoWindow } from "../common/CustomInfowindowCreator";
import { hexToRgba } from "../../utils";

interface MapLineCreatorInterface {
  layer: Layer;
  order: number;
}

export function MapLineCreator({ layer, order }: MapLineCreatorInterface) {
  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=1000&outputFormat=application%2Fjson`;

  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });

  const [data, setData] = useState({ features: [] });
  const [current, setCurrent] = useState<Feature<Geometry> | null>(null);

  const fetchData = useMemo(() => {
    return fetch(layer_url).then((response) => response.json());
  }, [layer_url]);

  useEffect(() => {
    fetchData.then(setData).catch(console.error);
  }, [fetchData]);

  const getLineColor = (f: Feature) => {
    const color_fill_hex = f.getProperties().color_hex?.trim() || "#7F7F7F";
    const opacity_fill = Number(f.getProperties().opacity) || 100;
    const color_fill = hexToRgba(color_fill_hex, opacity_fill);

    const width = Number(f.getProperties().width) || 6;

    return <RStyle.RStroke width={width} color={color_fill} />;
  };

  return (
    <>
      <RLayerVector
        zIndex={order} 
        format={parser} 
        url={layer_url} 
        visible={layer.visible}
        onPointerEnter={useCallback((e: any) => setCurrent(e.target), [])}
        onPointerLeave={useCallback((e: any) => current === e.target && setCurrent(null), [current])}
      >
        <RStyle.RStyle render={useCallback(getLineColor, [])} />
      </RLayerVector>

      {layer.infowindow && current && (
        <RLayerVector zIndex={5}>
          <RFeature geometry={current.getGeometry()}>
            <RPopup trigger="hover" positioning="center-left">
              <CustomInfoWindow infowindow={layer.infowindow} properties={current.getProperties()} />
            </RPopup>
          </RFeature>
        </RLayerVector>
      )}
    </>
  );
}