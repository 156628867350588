import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RFeature, RLayerVector, RPopup, RStyle } from "rlayers";
import { setModalState } from "../../../../redux/modal";
import PopUp from "../../../PopUp/PopUp";
import { Layer } from "../../config/interfaces";


interface AtlasLayerInterface {
  layers: Layer[];
}

export function NNGU_OOPT(layers: AtlasLayerInterface) {

  const dispatch = useDispatch();

  const layer = layers.layers[0];
  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });
  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { 
        name: string, 
        zakon: string, 
        color_hex: string } }[];
  };

  const fetchData = useMemo(() => {
    return fetch(layer_url).then((raw) => raw.json() as Promise<inputDataType>);
  }, [layer_url]);
  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ features: [] } as inputDataType);

  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);
  RFeature.hitTolerance = 0;

  const [current, setCurrent] = React.useState(
    null as Feature<Geometry> | null
  );


  const getLineColor = (f: any) => {
    let color = "#e24c4c";
    return (
        <>
        <RStyle.RFill color={color}/>
        <RStyle.RStroke width={1} color={'#f5f5f5'}/>
        </>
        ); 
  }
  //console.log(current?.getGeometry()?.getExtent());
  //console.log(current);

  //const isSelected = useSelector((state: any) => state.selectFeature.isSelected);
  //const selectedFeature = useSelector((state: any) => state.selectFeature.feature);

  const handleSetCurrent = (f: any) => {
    setCurrent(f);
  }

  const handleModalOpen = (title: string, text: string) => {
    dispatch(
      setModalState({
        isVisible: true,
        title: title,
        content: <p> {text} </p>,
      })
    );
  };

  const readMore = () => {
    if (current?.get('name')) {
      handleModalOpen(current?.get('name'), current?.get('zakon'));
    } else {
      handleModalOpen("", current?.get('zakon'));
    }
  };
  const popup = React.useRef<RPopup>(null);

  return (
    <>

      <RLayerVector
        zIndex={1}
        format={parser}
        url={layer_url}
        onPointerEnter={useCallback((e: any) => handleSetCurrent(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current === e.target && handleSetCurrent(null),
          [current]
        )}

      >
        <RStyle.RStyle
          render={useCallback(
            (f: any) => (getLineColor(f)),
            [data]
          )}
        />
      </RLayerVector>

      <RLayerVector zIndex={5}>
        {current ? (
          (<div>
            <RFeature geometry={current.getGeometry()}>
              <RPopup trigger="hover" autoPosition={true} ref={popup}>
                  <PopUp popupData={{title: current.get('name'), paragraph: current.get('zakon')}} onClick={readMore}/>
              </RPopup>
            </RFeature>
          </div>)
        ) : null}
      </RLayerVector>
    </>
  );
}
