import React, { useMemo, useCallback } from "react";
import GeoJSON from "ol/format/GeoJSON";
import { RLayerVector, RFeature, ROverlay, RStyle } from "rlayers";
import { Layer } from "../../config/interfaces";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import { 
    SimpleRectangle, 
    SimpleRectangleSelectedInfoWindow
  } from "../../elements/diagrams";
import { useSelector} from "react-redux";


interface AtlasLayerInterface {
  layers: Layer[];
}

export function YufuTurmarshrut(layers: AtlasLayerInterface) {
  // layer1
  const layer = layers.layers[0];

  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=200&outputFormat=application%2Fjson`;
  
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });
  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { 
        name_point: string, 
        description: string,
        color_hex: string} }[];
  };
  const fetchData = useMemo(() => {
    return fetch(layer_url).then((raw) => raw.json() as Promise<inputDataType>);
  }, [layer_url]);

  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ features: [] } as inputDataType);

  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);

  const isSelected = useSelector((state: any) => state.selectFeature.isSelected);
  const selectedFeature = useSelector((state: any) => state.selectFeature.feature);

  // layer 3
  type inputDataTypeLine = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { 
        name_point: string, 
        description: string,
        color_hex: string} }[];
  };
  const layer3 = layers.layers[1];
  const layer_url3 = `https://geo.touristatlas.ru/geoserver/${layer3.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer3.workspace}%3A${layer3.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser3 = new GeoJSON({ featureProjection: "EPSG:4326" });

  const fetchData3 = useMemo(() => {
    return fetch(layer_url3).then((raw) => raw.json() as Promise<inputDataTypeLine>);
  }, [layer_url3]);

  RFeature.hitTolerance = 0;
  const [data3, setData3] = React.useState({ features: [] } as inputDataType);
  React.useEffect(() => {
    fetchData3.then((r) => setData3(r));
  }, []);

  // layer vrezka
  type inputDataTypeVrezka = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { 
        name: string} }[];
  };
  const vrezka = layers.layers[2];
  const vrezka_url = `https://geo.touristatlas.ru/geoserver/${vrezka.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${vrezka.workspace}%3A${vrezka.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser_vrezka = new GeoJSON({ featureProjection: "EPSG:4326" });

  const fetchDataVrezka = useMemo(() => {
    return fetch(vrezka_url).then((raw) => raw.json() as Promise<inputDataTypeVrezka>);
  }, [vrezka_url]);

  RFeature.hitTolerance = 0;
  const [data_vrezka, setDataVrezka] = React.useState({ features: [] } as inputDataTypeVrezka);
  React.useEffect(() => {
    fetchDataVrezka.then((r) => setDataVrezka(r));
  }, []);

  const handleVrezkaClick = () => {
    alert('click');
  }

  const getLineColor = (f: any) => {
    let color = f.values_.color_hex;
    if (!color) {
        color = "#7F7F7F";
    }
    else {
        color = f.values_.color_hex.trim();
    }
    let width = 6;
    let width_props = f.values_.width;
    if (width_props) {
        width = width_props;
    }
    return (<RStyle.RStroke width={width} color={color}/>); 
  }

  const getPolygonColor = (f: any) => {
    let color = f.values_.color_hex;
    if (!color) {
        color = "rgba(255, 255, 255, 0.2)";
    }
    else {
        color = f.values_.color_hex.trim();
    }
    return (<>
    <RStyle.RFill color={color}/>
    <RStyle.RStroke width={2} color={"#373737"}/>
    </>
    ); 
  }

  return (
    <>
      <RLayerVector
        zIndex={2}
        visible={false}
      >
        {isSelected ?
            <RFeature geometry={
              new Point(fromLonLat([
                selectedFeature.geometry.coordinates[0], 
                selectedFeature.geometry.coordinates[1]
                ]))}>
            <ROverlay 
            positioning="top-left">
              <SimpleRectangleSelectedInfoWindow 
              value={selectedFeature.id.split('.')[1]} 
              width={24} height={24} 
              color={'#ffffff'} 
              background={selectedFeature.properties.color_hex}
              minValue={24}
              label={selectedFeature.properties.name_point}
              text={selectedFeature.properties.description}/>
           </ROverlay>
          </RFeature>
          : 
          <RFeature geometry={new Point(fromLonLat([0, 0]))}>
            <ROverlay positioning="center-left"></ROverlay>
          </RFeature>
           }
        {data.features.map((f) => (
        <RFeature 
          key={f.id}
          geometry={new Point(fromLonLat(f.geometry.coordinates))}
          properties={f.properties}
          >
          <ROverlay 
          positioning="top-left">
              <SimpleRectangle 
                feature={f}
                value={0} 
                width={16} height={16} 
                color={'#ffffff'} 
                background={f.properties.color_hex}
                minValue={0}
                label={f.properties.name_point}/>
           </ROverlay>
          </RFeature>
        ))}

      </RLayerVector>

      <RLayerVector
        zIndex={2}
        format={parser3}
        url={layer_url3}
      >
        <RStyle.RStyle
          render={useCallback(
            (f: any) => (getLineColor(f)),
            [data3]
          )}
        />
      </RLayerVector>

      <RLayerVector
        zIndex={1}
        format={parser_vrezka}
        url={vrezka_url}
        //onClick={handleVrezkaClick}
      >
        <RStyle.RStyle
          render={useCallback(
            (f: any) => (getPolygonColor(f)),
            [data3]
          )}
        />
      </RLayerVector>
    </>
  );
}
