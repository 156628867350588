import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { select, deselect } from "../../../../redux/map/featureReducer";
import { setModalState } from "../../../../redux/modal";
import PopUp from "../../../PopUp/PopUp";
import styles from "./RectangleDiagramsTest.module.css";

interface RectangleDiagramInterface {
  value: number;
  background: string;
  color: string;
  width: number;
  height: number;
  label: string;
  text: string;
  subtitle?: string;
  subscription?: string;
}

export const SimpleRectangleSelectedInfoWindowListNew = (
  props: RectangleDiagramInterface
) => {
  const [isShown, setIsShown] = useState(false);

  const dispatch = useDispatch();

  let {
    width,
    height,
    color = "#ff0000",
    background,
    value,
    label,
    text,
    subtitle,
    subscription
  } = props;

  let diagrammSizeforLabel = width;

  if (width < 12 && height < 12) {
    width = 12;
    height = 12;
  }

  if (width < 28) {
    diagrammSizeforLabel = 28;
  }

  if (width > 400 && height > 400) {
    width = 250;
    height = 250;
  }

  const handleMouseEnter = () => {
    setIsShown(true);
    dispatch(select());
  };

  const handleMouseLeave = () => {
    setIsShown(false);
    dispatch(deselect());
  };

  const handleModalOpen = (title: string, text: string) => {
    dispatch(
      setModalState({
        isVisible: true,
        title: title,
        content: <p> {text} </p>,
      })
    );
  };

  const readMore = () => {
    if (label) {
      handleModalOpen(label, text);
    } else {
      handleModalOpen("", text);
    }
  };
  const popupData = {
    title: label,
    subTitle: subtitle,
    paragraph: text,
    subscription: subscription,
  };

  return (
    <div
      className={styles.rectangleDiagrammFull}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <div
        style={{
          width: `${diagrammSizeforLabel}px`,
          height: `${diagrammSizeforLabel}px`,
          backgroundColor: `${background}`,
          color: `${color}`,
        }}
        className={styles.rectangleDiagramFrame}
      >

      </div>
      <PopUp popupData={popupData} onClick={readMore} />
    </div>
  );
};
