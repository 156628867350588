import React, { useCallback } from "react";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import Feature from "ol/Feature";
import { RLayerVector, RFeature, RStyle, RPopup, ROverlay } from "rlayers";
import { Layer } from "../../config/interfaces";
import natural_monuments from "../../icons/rectangle.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import { 
  SimpleRectangle, 
  SimpleRectangleSelectedInfoWindow 
} from "../../elements/diagrams";
import styles from "../../Map.module.css";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function STGUSelskiyTurizm(layers: AtlasLayerInterface) {
  // Берем clusterId и  map_config из стора:
  const { clusterId, map_config } = useSelector(
    (state: RootState) => state.map
  );

  const layer = layers.layers[0];
  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });
  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { name: string, type: string, color_hex: string } }[];
  };
  const fetchData = fetch(layer_url).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ features: [] } as inputDataType);
  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);
  RFeature.hitTolerance = 0;

  const isSelected = useSelector((state: any) => state.selectFeature.isSelected);
  const selectedFeature = useSelector((state: any) => state.selectFeature.feature);

  const getIcon = (f: any) => {
    let color = f.values_.color_hex.trim();
    return (<RStyle.RIcon src={natural_monuments} color={color}/>);   
  }

  const flyTo = (e: any) => {
    const [xMin, yMin, xMax, yMax] = e.target.getGeometry().getExtent();
    const newExt = [xMin + xMin * 0.002, yMin, xMax + xMax * 0.002, yMax];
    e.map.getView().fit(newExt, {
      duration: 750,
      maxZoom: 6,
    })
  }

  return (
    <>
    <RLayerVector
        zIndex={2}
        format={parser}
        url={layer_url}
        visible={false}
      >
        {isSelected ?
            <RFeature geometry={
              new Point(fromLonLat([
                selectedFeature.geometry.coordinates[0], 
                selectedFeature.geometry.coordinates[1]
                ]))}>
            <ROverlay 
            positioning="top-left">
              <SimpleRectangleSelectedInfoWindow 
                value={selectedFeature.id.split('.')[1]} 
                width={24} height={24} 
                color={'#ffffff'} 
                background={selectedFeature.properties.color_hex}
                minValue={24}
                label={selectedFeature.properties.name}
                text={selectedFeature.properties.description}/>
           </ROverlay>
          </RFeature>
          : 
          <RFeature geometry={new Point(fromLonLat([0, 0]))}>
            <ROverlay positioning="center-left"></ROverlay>
          </RFeature>
           }
        {data.features.map((f) => (
        <RFeature 
          key={f.id}
          geometry={new Point(fromLonLat(f.geometry.coordinates))}
          properties={f.properties}
          >
          <ROverlay 
          positioning="top-left">
              <SimpleRectangle 
                feature={f}
                value={0} 
                width={16} height={16} 
                color={'#ffffff'} 
                background={f.properties.color_hex}
                minValue={0}
                label={f.properties.name}/>
           </ROverlay>
          </RFeature>
        ))}
      </RLayerVector>
    </>
  );
}
