import React from "react";
import styles from "./PageMapsDescription.module.scss";
import { ContentBlockByType } from "../DataBlocks/DataBlocks";
import { AbstractLayer } from "../../pages/ConstructorPage";

export default function PageMapsDescription({
  checkedLayersData,
}: {
  checkedLayersData: AbstractLayer[];
}) {
  return (
    <div className={styles.container}>
      <div className={styles.pageTitle}>Описание элементов карты</div>
      <div className={styles.dataContainer}>
        {checkedLayersData.map((layerData, index) => {
          return (
            layerData.content_block.type && (
              <div key={index} className={styles.dataModule}>
                <div className={styles.dataContent}>
                  <div className={styles.postTitle}>{layerData.post_title}</div>
                  <div className={styles.chapterTitle}>
                    {layerData.chapter_title}
                  </div>
                  <ContentBlockByType contentBlock={layerData.content_block} />
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}
