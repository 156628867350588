import React, { useCallback } from "react";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import Feature from "ol/Feature";
import { RLayerVector, RFeature, RStyle } from "rlayers";
import { Layer } from "../../config/interfaces";
import {getColorByScale} from "../../utils/get_color_by_scale";
import {ArgoPopup} from "../../elements/popups/AgroPopup";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function Population(layers: AtlasLayerInterface) {
  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { name: string, agrofarm: number } }[];
  };
  // layer 2
  const layer2 = layers.layers[0];
  const layer_url2 = `https://geo.touristatlas.ru/geoserver/${layer2.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer2.workspace}%3A${layer2.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser2 = new GeoJSON({ featureProjection: "EPSG:4326" });

  const fetchData2 = fetch(layer_url2).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data2, setData2] = React.useState({ features: [] } as inputDataType);
  const [current2, setCurrent2] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData2.then((r) => setData2(r));
  }, []);

  let classified_prop = "year_2020"
  if (layer2.classified_prop) {
    classified_prop = layer2.classified_prop;
  };
  
  const renderStyle = useCallback((f: any) => {
    let color = "#ffffff";
    if (layer2.scale) {
      color = getColorByScale(f, classified_prop, layer2.scale, "rgba(240, 240, 240, 0.1)");
    }
    return (
      <>
      <RStyle.RFill color={color}/>
      <RStyle.RStroke width={0.5} color={'#f5f5f5'}/>
      </>
      ); 
  }, [classified_prop])

  return (
    <>
    { layer2.label ? 
      <RLayerVector zIndex={5}>
        {current2 ? (
          (<div>
            <RFeature geometry={current2.getGeometry()}>
              <ArgoPopup name={current2.get('name')} value={current2.get(classified_prop)}/>
            </RFeature>
          </div>)
        ) : null}
      </RLayerVector> : null }

      <RLayerVector
        zIndex={1}
        format={parser2}
        url={layer_url2}
        onPointerEnter={useCallback((e: any) => setCurrent2(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current2 === e.target && setCurrent2(null),
          [current2]
        )}
      >
        <RStyle.RStyle
          render={renderStyle}
        />
      </RLayerVector>

      
    </>
  );
}
