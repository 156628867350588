import Feature from "ol/Feature";
import { Geometry } from "ol/geom";
import { messages } from "../edit/constants";

export function clearEmptyFields(obj: any) {
  for (let key in obj) {
    if (obj[key] === "" || obj[key] === undefined || obj[key] === null) {
      delete obj[key];
    } else if (Array.isArray(obj[key])) {
      if (obj[key].length === 0) {
        delete obj[key];
      } else {
        obj[key] = obj[key].filter((item: any) => {
          if (typeof item === "object") {
            clearEmptyFields(item);
            return Object.keys(item).length > 0;
          } else {
            return true;
          }
        });
        if (obj[key].length === 0) {
          delete obj[key];
        }
      }
    } else if (typeof obj[key] === "object") {
      clearEmptyFields(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  }

  return obj;
}

export function removeEmptyProps(obj: Record<string, any>) {
  for (const [key, value] of Object.entries(obj)) {
    if (value === undefined) {
      delete obj[key];
    } else if (Array.isArray(value)) {
      if (!value.length) {
        delete obj[key];
      } else if (typeof value[0] === "object") {
        value.forEach(removeEmptyProps);
        if (value.every(isEmptyObject)) {
          delete obj[key];
        }
      } else {
        if (value.every((val) => typeof val === "undefined")) {
          delete obj[key];
        }
      }
    } else if (value && typeof value === "object") {
      removeEmptyProps(value);
      if (isEmptyObject(value)) {
        delete obj[key];
      }
    }
  }
  return obj;
}
function isEmptyObject(obj: Record<string, any>) {
  return Object.keys(obj).length === 0;
}

export function generateColors(baseColor: string, numElements: number) {
  const alphaStep = 1 / numElements;

  const colors = Array.from({ length: numElements }, (_, index) => {
    if (index + 1 === numElements) {
      return `rgba(${baseColor}, 1)`;
    }
    const alpha = alphaStep * (index + 1);
    return `rgba(${baseColor}, ${alpha.toFixed(2)})`;
  });

  return colors;
}

export function isNumber(value: string | number) {
  if (!isNaN(value as number) && value !== "") {
    return true;
  } else {
    return false;
  }
}

export const getCurrentRegionData = (data: Feature<Geometry> | null) => {
  if (data) {
    const newData = {
      subjectId: data.getId(),
      univerityName: data.getProperties().university,
      regionName: data.getProperties().name,
      clusterId: data.getProperties().cluster_id,
    };
    return newData;
  }
};

export function validateNotEmpty(value: any) {
  if (!value || value.length === 0) {
    return messages.empty;
  }
}

export function validateNotEmptyArray(array: any[]) {
  if (!array.length) {
    return messages.empty;
  }
}

export function validateMapCode(code: string) {
  if (!/^\d{1,3}-\d{1,3}$/.test(code)) {
    return messages.codeFormat;
  }
}

export const validateJSON = (value: string) => {
  try {
    JSON.parse(value);
  } catch (error) {
    return messages.invalidJson;
  }
};

export const getValidationProps = <T>(
  name: keyof T,
  touched: { [P in keyof T]?: any },
  errors: { [P in keyof T]?: any }
) => ({
  isValid: !touched[name] || !errors[name],
  validationMessage: errors[name],
});

const getDeepProp = (key: string, obj: any) => {
  const props = key.replace(/\[/g, ".").replace(/]/g, "").split(".");
  return props.reduce((acc, key) => acc?.[key], obj);
};

export const getDeepValidationProps = <T>(
  name: string,
  touched: { [P in keyof T]?: any },
  errors: { [P in keyof T]?: any }
) => ({
  isValid: !getDeepProp(name, touched) || !getDeepProp(name, errors),
  validationMessage: getDeepProp(name, errors),
});

export function measureTextWidth(input: string, fontStyles: string): number {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (canvas && context) {
    context.font = fontStyles;
    const width = context.measureText(input).width;
    return width;
  }
  return 0;
}

export function shortenString(
  input: string,
  maxPixelsWidth: number,
  fontStyles: string
): string {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (canvas && context) {
    context.font = fontStyles;
    if (context.measureText(input).width <= maxPixelsWidth) {
      return input;
    } else {
      let shortened = "";
      let currentWidth = 0;
      for (let i = 0; i < input.length; i++) {
        const char = input[i];
        const charWidth = context.measureText(char).width;
        if (currentWidth + charWidth <= maxPixelsWidth) {
          shortened += char;
          currentWidth += charWidth;
        } else {
          break;
        }
      }
      return `${shortened}...`;
    }
  }
  return input;
}

export function checkForNestedField(obj: any) {
  if (typeof obj !== "object") {
    return false;
  }

  if ("nested" in obj) {
    return true;
  }

  for (let key in obj) {
    if (typeof obj[key] === "object" && checkForNestedField(obj[key])) {
      return true;
    }
  }

  return false;
}

export function measureTextBlockWidth(
  text: string,
  fontStyles: string,
  maxWidth: number,
  lineHeight: number
): number {
  const textContent = text.trim();
  if (measureTextWidth(textContent.trim(), fontStyles) < maxWidth) {
    return maxWidth;
  }

  let currentText = "";
  let prevIndex = 0;
  let startLineIndex = 0;
  let currentHeight = lineHeight;
  const widths: number[] = [];

  const virtualElement = document.createElement("div");
  virtualElement.style.maxWidth = `${maxWidth}px`;
  virtualElement.style.overflowWrap = "break-word";
  virtualElement.style.font = fontStyles;
  virtualElement.style.lineHeight = `${lineHeight}px`;

  const indexes: number[] = Array.from(textContent).reduce(
    (indices: number[], char, index) => {
      if (char === " " || char === "-") {
        indices.push(index);
      }
      return indices;
    },
    []
  );
  indexes.push(textContent.length - 1);

  indexes.forEach((charIndex, index) => {
    currentText = textContent.substring(0, charIndex + 1);
    virtualElement.textContent = currentText;
    document.body.appendChild(virtualElement);
    const height = virtualElement.offsetHeight;
    document.body.removeChild(virtualElement);

    if (height > currentHeight) {
      const line = textContent.substring(startLineIndex, prevIndex);
      startLineIndex = prevIndex;
      currentHeight = height;
      widths.push(measureTextWidth(line.trim(), fontStyles));
    }
    if (indexes.length === index + 1) {
      const line = textContent.substring(startLineIndex, textContent.length);
      widths.push(measureTextWidth(line.trim(), fontStyles));
    }
    prevIndex = charIndex + 1;
  });
  return Math.ceil(Math.max(...widths));
}

export function createAlternateColorsArray(
  colors: string[],
  numElements: number
): string[] {
  const alternateColorsArray: string[] = [];

  for (let i = 0; i < numElements; i++) {
    const colorIndex = i % colors.length;
    alternateColorsArray.push(colors[colorIndex]);
  }

  return alternateColorsArray;
}

export function roundToDecimal(value: number, decimalPlaces: number): number {
  if (value % 1 === 0) {
    return value;
  } else {
    return Number(value.toFixed(decimalPlaces));
  }
}

export const findLargestNumberAmongArrays = (
  arraysOfObjects: any[]
): number => {
  let largestNumber = 0;

  arraysOfObjects.forEach((obj) => {
    const maxNumInArray = Math.max(...obj.data);
    if (maxNumInArray > largestNumber) {
      largestNumber = maxNumInArray;
    }
  });

  return largestNumber;
};
