import React from "react";
import styles from "./AboutWindow.module.scss";

export type AboutWindowProps = {};

const AboutWindow: React.FC<AboutWindowProps> = () => {
  return (
    <div className={styles.container}>
      <div className={styles.mainTitle}>О НАС</div>
      <div className={styles.newsContainer}>
        <div className={styles.newsTitleContainer}>
          <div className={styles.newsTitle}>
            «Туристский атлас. Путеводитель инвестора и руководителя» 
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.paragraphContainer}>
            <div className={styles.paragraph}>
              — это информационно-аналитический сайт, который позволяет наглядно
              представить рекреационный, логистический, инвестиционный потенциал
              региона, туристской макротерритории или отдельной локации.
            </div>
            <div className={styles.paragraph}>
              Основной фокус внимания уделяется факторам, влияющим на принятие
              управленческих и бизнес-решений потенциальными инвесторами
              и представителями местных органов власти.
            </div>
          </div>
          <div className={styles.imagesContainer}>
            {[34, 35, 36, 37, 38, 39].map((number) => (
              <section className={styles.image} key={number}>
                <img
                  src={require(`./images/image-${number}.png`)}
                  alt={"Изображение"}
                />
              </section>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.newsContainer}>
        <div className={styles.newsTitleContainer}>
          <div className={styles.newsTitle}>Экспертное взаимодействие </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.paragraphContainer}>
            <div className={styles.paragraph}>
              Совместно с ведущими российскими ВУЗами, специалисты в области
              картографии, экономики, этнографии и разработки работают над тем,
              чтобы представленные в Атласе материалы были информативными и
              удобными для понимания и применения.
            </div>
          </div>
          <div className={styles.iconsContainer}>
            <div className={styles.icon}>
              <div className={styles.iconTitle}>8</div>
              <div className={styles.iconDescription}>Ведущих ВУЗов</div>
            </div>
            <div className={styles.icon}>
              <div className={styles.iconTitle}>74</div>
              <div className={styles.iconDescription}>Специалиста</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutWindow;
