import { makeRequest } from "../common/ApiService";
import { IUser, IUserSignUpData } from "../types/user";
import { OAuthToken, saveTokensToStorage } from "./tokenService";

export const CLIENT_ID = "c8674f0dec2241abbda468ae793939f4";

export const makeTokenRequest = async (formData: URLSearchParams) => {
  return await fetch("/api/oauth/v2/token", {
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formData,
  });
};

export async function loginWithCredentials(
  email: string,
  password: string,
): Promise<boolean> {
  const formData = new URLSearchParams();
  formData.append("email", email);
  formData.append("password", password);
  formData.append("client_id", CLIENT_ID);
  formData.append("grant_type", "client_credentials");

  const resp = await makeTokenRequest(formData);

  if (resp.ok) {
    const data = await resp.json();
    saveTokensToStorage(data);

    return true;
  }

  return false;
}

export async function getUserInfo() {
  return await makeRequest<IUser>(`/api/users/iam`);
}

export async function userSignUp(signUpData: IUserSignUpData) {
  const formData = {
    login: signUpData.login,
    email: signUpData.email,
    password: signUpData.password,
  };

  const tokens = await makeRequest<OAuthToken>(
    "/api/users/sign-up",
    {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    },
    true,
  );

  saveTokensToStorage(tokens);

  return true;
}
