import { Post } from "../../types/content-types";

export const mockData: Post = {
  id: 1,
  content_blocks: [
    {
      type: 10,
      header: "Perfect Economy Diagram",
      sub_header: "Looks seriously",
      description: "Tech industry 2023 performance",
      x_legend: ["Yandex", "Ozon", "Softline", "Tinkoff", "Sberbank"],
      y_legend: ["Q1 2023", "Q2 2023", "Q3 2023", "Q4 2023"],
      x_data: [5864, 2130.5, 6823, 9875.4, 4583.1],
    },
    {
      type: 10,
      header: "Key takeaways",
      paragraph:
        "Technology thrives on innovation, and today, the sector is in a period of renewal and reinvention. Tech is healthy, vibrant, and moving forward assertively, driven by strong global demand and broad interest in new technologies. What looked like a slowdown was actually a reset. After the Covid-19 spending bubble, demand slowed, valuations fell, and profitability mattered again. The sector responded, cutting costs and laying off thousands, then bounced back quickly with market caps near peak levels again.",
      url: "https://studfile.net/html/2706/162/html_5VyXqkYzP7.zLyT/img-ST7BOf.png",
    },
  ],
};
