import { boundingExtent } from "ol/extent";
import "ol/ol.css";
import { fromLonLat } from "ol/proj";
import { register } from "ol/proj/proj4.js";
import proj4 from "proj4";
import styles from "./Map.module.css";

import { useDispatch, useSelector } from "react-redux";
import { set_bbox } from "../../redux/map/mapReducers";

import { MapAzimut } from "./MapAzimut";
import { MapMercator } from "./MapMercator";
import { maps } from "./config/index";

import { selectMap } from "../../redux/map/selectors";

export default function Map() {
  const reduxMap_config = useSelector(selectMap).map_config;
  proj4.defs(
    "EPSG:3576",
    "+proj=laea +lat_0=90 +lon_0=90 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs +type=crs"
  );
  register(proj4);

  let map_config = reduxMap_config;
  if (map_config === null) {
    map_config = maps[0].map_config;
  }

  const isMercator = map_config.projection === "EPSG:3857";

  const dispatch = useDispatch();

  if (map_config.extent) {
    const extent = boundingExtent([
      fromLonLat([map_config.extent.xmin, map_config.extent.ymin]),
      fromLonLat([map_config.extent.xmax, map_config.extent.xmax]),
    ]);
    dispatch(set_bbox(extent));
  }

  return (
    <div className={styles.mapWrap}>
      {isMercator ? (
        <MapMercator map_config={map_config} />
      ) : (
        <MapAzimut map_config={map_config} />
      )}
    </div>
  );
}
