import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
  TooltipItem,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels
);

export type PieChartProps = {
  radius?: number;
  values: { value: number; color: string }[];
  tooltip?: boolean;
  tooltipDescription?: string;
};

const PieChart: React.FC<PieChartProps> = ({
  radius,
  values,
  tooltip,
  tooltipDescription,
}) => {
  const tooltipOptions = tooltip
    ? tooltipDescription
      ? {
          callbacks: {
            label: (tooltipItem: TooltipItem<"pie">) => {
              const dataset = tooltipItem.dataset;
              const value = dataset.data[tooltipItem.dataIndex];
              return `${value} ${tooltipDescription}`;
            },
          },
        }
      : {
          enabled: true,
        }
    : {
        enabled: false,
      };

  let data: number[] = [];
  let backgroundColor: string[] = [];

  values.forEach((element) => {
    data.push(element.value);
    backgroundColor.push(element.color);
  });

  const diagramData = {
    datasets: [
      {
        data: data,
        backgroundColor: backgroundColor,
        borderWidth: 1,
      },
    ],
  };

  const diagramOptions = {
    legend: {
      display: false,
    },
    radius: radius,
    plugins: {
      tooltip: tooltipOptions,
      datalabels: {
        display: false,
      },
    },
  };

  return <Pie data={diagramData} options={diagramOptions} />;
};

export default PieChart;
