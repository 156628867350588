import React, { useCallback } from "react";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import Feature from "ol/Feature";
import { RLayerVector, RFeature, RStyle, ROverlay, RPopup } from "rlayers";
import { Layer } from "../../config/interfaces";
import cities from "../../icons/rectangle.svg";
import {getColorByScale} from "../../utils/get_color_by_scale";
import {ArgoPopup} from "../../elements/popups/AgroPopup";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import { useSelector } from "react-redux";
import { SimpleRectangle, SimpleRectangleSelectedLabel } from "../../elements/diagrams";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function Agro(layers: AtlasLayerInterface) {

  // layer1
  const layer = layers.layers[0];
  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });
  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { name: string, agrofarm: number } }[];
  };
  const fetchData = fetch(layer_url).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ features: [] } as inputDataType);
  const [current, setCurrent] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);

  const [features, setFeatures] = React.useState(() =>
    Object.keys(data.features).map(
      (f) =>
        new Feature({
          geometry: new Point(fromLonLat([44.2558, 46.3078])),
          name: f,
        })
    )
  );

  // layer 2
  const layer2 = layers.layers[1];
  const layer_url2 = `https://geo.touristatlas.ru/geoserver/${layer2.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer2.workspace}%3A${layer2.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser2 = new GeoJSON({ featureProjection: "EPSG:4326" });

  const fetchData2 = fetch(layer_url2).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data2, setData2] = React.useState({ features: [] } as inputDataType);
  const [current2, setCurrent2] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData2.then((r) => setData2(r));
  }, []);

  // layer 2
  const layer3 = layers.layers[2];
  const layer_url3 = `https://geo.touristatlas.ru/geoserver/${layer3.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer3.workspace}%3A${layer3.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser3 = new GeoJSON({ featureProjection: "EPSG:4326" });

  const fetchData3 = fetch(layer_url3).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data3, setData3] = React.useState({ features: [] } as inputDataType);
  const [current3, setCurrent3] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData3.then((r) => setData3(r));
  }, []);

  const getIcon = (f: any) => {
    //let color = f.values_.color_hex.trim();
    const color = "#0334b9";
    return (<RStyle.RIcon src={cities} color={color}/>);   
  }

  let classified_prop = "year_2020"
  if (layer2.classified_prop) {
    classified_prop = layer2.classified_prop;
  };
  
  const renderStyle = useCallback((f: any) => {
    let color = "#ffffff";
    if (layer2.scale) {
      color = getColorByScale(f, classified_prop, layer2.scale, "rgba(240, 240, 240, 0.1)");
    }
    return (
      <>
      <RStyle.RFill color={color}/>
      <RStyle.RStroke width={0.5} color={'#f5f5f5'}/>
      </>
      ); 
  }, [classified_prop])

  const isSelected = useSelector((state: any) => state.selectFeature.isSelected);
  const selectedFeature = useSelector((state: any) => state.selectFeature.feature);

  return (
    <>
    {layer.visible ? 
        <RLayerVector
        zIndex={2}
        visible={false}
      >
        {isSelected ?
            <RFeature geometry={
              new Point(fromLonLat([
                selectedFeature.geometry.coordinates[0], 
                selectedFeature.geometry.coordinates[1]
                ]))}>
            <ROverlay 
            positioning="center-left">
              <SimpleRectangleSelectedLabel 
                value={selectedFeature.properties.agrofarm} 
                width={selectedFeature.properties.agrofarm} height={selectedFeature.properties.agrofarm} 
                color={'#ffffff'} 
                background={"#0334b9"}
                minValue={24}
                label={selectedFeature.properties.name}/>
           </ROverlay>
          </RFeature>
          : 
          <RFeature geometry={new Point(fromLonLat([0, 0]))}>
            <ROverlay positioning="center-left"></ROverlay>
          </RFeature>
           }
        {data.features.map((f) => (
        <RFeature 
          key={f.id}
          geometry={new Point(fromLonLat(f.geometry.coordinates))}
          properties={f.properties}
          >
          <ROverlay 
          positioning="center-left">
              <SimpleRectangle 
                feature={f}
                value={f.properties.agrofarm} 
                width={f.properties.agrofarm} height={f.properties.agrofarm} 
                color={'#ffffff'} 
                background={"#0334b9"}
                minValue={24}
                label={f.properties.name}/>
           </ROverlay>
          </RFeature>
        ))}

      </RLayerVector> : null }

    { layer.label ? 
      <RLayerVector zIndex={5}>
        {current2 ? (
          (<div>
            <RFeature geometry={current2.getGeometry()}>
              <ArgoPopup name={current2.get('name') + " ГО"} value={current2.get(classified_prop) + "%"}/>
            </RFeature>
          </div>)
        ) : null}
      </RLayerVector> : null }

      <RLayerVector
        zIndex={1}
        format={parser2}
        url={layer_url2}
        visible={layer2.visible}
        onPointerEnter={useCallback((e: any) => setCurrent2(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current === e.target && setCurrent2(null),
          [current]
        )}
      >
        <RStyle.RStyle
          render={renderStyle}
        />
      </RLayerVector>
      <RLayerVector
        zIndex={1}
        format={parser3}
        url={layer_url3}
        visible={layer3.visible}
        onPointerEnter={useCallback((e: any) => setCurrent3(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current === e.target && setCurrent3(null),
          [current]
        )}
      >
        <RStyle.RStyle
          render={renderStyle}
        />
      </RLayerVector>
    </>
  );
}
