import BaseStyles from "../components/common.module.scss";
import Navbar from "../components/Navbar/Navbar";

export default function DocumentsPage() {
  return (
    <>
      <div className={BaseStyles.splashScreenOpacity} />
      <Navbar activePage={"Документы"} />
      <div>Документы</div>
    </>
  );
}
