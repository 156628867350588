import { RPopup } from "rlayers";
import styles from "./ArgoPopup.module.css";

interface ClusterPopupInterface {
    name: string,
    value: string,
}

export const ArgoPopup = (props: ClusterPopupInterface) => {

  return (
    <>
        <RPopup trigger="hover"
              positioning="center-left">
                <div className={styles.agroPopup}>
                    <div className={styles.agroPercent}>{String(props.value).replace(".", ",")}</div>
                    <div className={styles.agroName}>{props.name}</div>
                </div>
              </RPopup>
    </>
  );
};