import React from "react";
import styles from "./Tags.module.scss";

import { Block, Tag } from "../../types/content-types";
import ContentBlockBase from "../ContentBlockBase";

export interface TagsProps {
  contentBlock: Block;
}

const Tags = ({ contentBlock }: TagsProps) => {
  const columns: Tag[][] = [];
  const tags = contentBlock.tags ?? [];
  const isColumns = contentBlock.layout?.auto ?? false;
  if (isColumns) {
    const firstColumnElements = Math.ceil(tags.length! / 2);
    columns.push(tags.slice(0, firstColumnElements));
    columns.push(tags.slice(firstColumnElements, tags.length));
  } else {
    columns.push(tags);
  }

  return (
    <ContentBlockBase contentBlock={contentBlock}>
      <div className={styles.tagListColumns}>
        {columns.map((tags, index) => (
          <div
            key={index}
            className={
              isColumns ? styles.columnContainer : styles.columnContainer100
            }
          >
            {tags.map((tag, index) => (
              <div key={index}>
                <div className={styles.tagContainer}>
                  {tag.icon_url ? (
                    <img
                      src={tag.icon_url}
                      className={styles.icon}
                      alt={tag.title}
                    />
                  ) : (
                    <div
                      className={styles.icon}
                      style={{ backgroundColor: tag.color }}
                      key={`tags_${index}`}
                    />
                  )}
                  <div className={styles.title}>{tag.title}</div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </ContentBlockBase>
  );
};

export default Tags;
