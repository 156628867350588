import { useCallback, useMemo } from "react";
import { genFieldName, genNestedFieldName } from "./utils";
import { Block, Post } from "../../types/content-types";
import { useFormikContext } from "formik";
const initialTag = { title: "", color: "", icon_url: "" };

export function useEditorField({
  index,
  nestedIndex,
  isNested,
}: {
  index: number;
  nestedIndex: number;
  isNested: boolean;
}) {
  const { setFieldValue, values, handleChange } = useFormikContext<Post>();
  const fieldName = useCallback(
    (name: string): string => {
      return isNested
        ? genNestedFieldName(index, nestedIndex, name)
        : genFieldName(index, name);
    },
    [index, nestedIndex, isNested],
  );

  type FieldValue = string | number | undefined;
  const fieldValue = useCallback(
    (name: keyof Block) => {
      const block = values.content_blocks[index];
      const nestedBlock = values.content_blocks[index].nested?.[nestedIndex];
      return (isNested ? nestedBlock?.[name] : block[name]) as FieldValue;
    },
    [index, nestedIndex, isNested, values.content_blocks],
  );

  const blockValue = useMemo(() => {
    return isNested
      ? values.content_blocks[index].nested?.[nestedIndex]
      : values.content_blocks[index];
  }, [index, nestedIndex, isNested, values.content_blocks]);

  const tagsArrayFieldName = fieldName("tags");

  const genInnerFieldName = useCallback(
    (innerFieldIndex: number, fieldName: string) =>
      `${tagsArrayFieldName}.[${innerFieldIndex}].${fieldName}`,
    [tagsArrayFieldName],
  );

  const tags = blockValue?.tags ?? [];

  const addTag = () => {
    setFieldValue(tagsArrayFieldName, [...tags, initialTag]);
  };

  const deleteTag = (targetIndex: number) => {
    const filteredTags = tags?.filter(
      (_: any, blockIndex: number) => blockIndex !== targetIndex,
    );
    setFieldValue(tagsArrayFieldName, filteredTags);
  };

  return {
    fieldName,
    fieldValue,
    blockValue,
    addTag,
    tags,
    deleteTag,
    genInnerFieldName,
    handleChange,
    setFieldValue,
  };
}
