import React from "react";
import styles from "./Map.module.css";

export default function MapOverLayer({
  children,
}: {
  children: React.ReactNode;
}) {
  return <main className={styles.main}>{children}</main>;
}
