import React from "react";
import { TextArea, TextField } from "../Components";

import { Post } from "../../types/content-types";
import { getDeepValidationProps } from "../../common/utils";
import { useFormikContext } from "formik";
import { SubFormProps } from "../types";

import { useEditorField } from "./useEditorField";

export default function TextForm({
  index,
  isNested = false,
  nestedIndex = 0,
}: SubFormProps) {
  const { handleChange, touched, errors } = useFormikContext<Post>();

  const { fieldName, fieldValue } = useEditorField({
    index,
    nestedIndex,
    isNested,
  });

  //Когда все заголовки переведут
  // if ((!isNested && index === 0) || (isNested && nestedIndex === 0)) {
  //   return (
  //     <TextField
  //       label="Заголовок (Название слоя)"
  //       onChange={handleChange}
  //       value={fieldValue("header")}
  //       name={fieldName("header")}
  //       {...getDeepValidationProps(fieldName("header"), touched, errors)}
  //     />
  //   );
  // }

  return (
    <>
      <TextField
        label="Заголовок (Название слоя)"
        onChange={handleChange}
        value={fieldValue("header")}
        name={fieldName("header")}
        {...(isNested &&
          getDeepValidationProps(fieldName("header"), touched, errors))}
      />
      <TextField
        label="Подзаголовок (Дополнительная/Вспомогательная информация)"
        onChange={handleChange}
        value={fieldValue("sub_header")}
        name={fieldName("sub_header")}
      />
      <TextArea
        label="Описание (Краткий вспомогательный текст  третьего уровня)"
        onChange={handleChange}
        value={fieldValue("description")}
        name={fieldName("description")}
        rows={8}
      />
      <TextArea
        label="Параграф (основной текст)"
        onChange={handleChange}
        value={fieldValue("paragraph")}
        name={fieldName("paragraph")}
        rows={8}
      />
    </>
  );
}
