import { PieSector, PieDataInterface } from "../config/interfaces";

const getSector = (feature_properties: any, sector: PieSector) => {
    Object.freeze(sector);

    const sectorData = {...sector}; // 👈 create copy

    for (var key in sectorData) {
        if (key === 'value') {
            sectorData[key] = feature_properties[sectorData[key]];
            if (sectorData[key] == null) {
                sectorData[key] = sectorData[key];
            }
        }
        if (key === 'color') {
            sectorData[key] = sectorData[key];
        }
    }

    return sectorData;

};

export const getPieData = (feature_properties: any, pie_data: PieDataInterface) => {
    Object.freeze(pie_data);

    const pieData = {...pie_data}; // 👈 create copy

    for (var key in pieData){
        if (key === 'title') {
            pieData[key] = feature_properties[pieData[key]];
            if (pieData[key] == null) {
                pieData[key] = pieData[key];
            }
        }
        if (key === 'tooltipDescriptionProp') {
            pieData[key] = feature_properties[pieData[key]];
            if (pieData[key] == null) {
                pieData[key] = '';
            }
        }
        if (key === 'size') {
            pieData[key] = feature_properties[pieData[key]];
            if (pieData[key] == null) {
                pieData[key] = pieData[key];
            }
        }
        if (key === 'sectors') {
            let sectors = pieData[key].map((s) => (getSector(feature_properties, s)));
            pieData[key] = sectors;
        }
        if (key === 'size_multiplication') {
            pieData[key] = pieData[key];
        }
        if (key === 'tooltip') {
            pieData[key] = pieData[key];
        }
        if (key === 'tooltipDescription') {
            pieData[key] = pieData[key];
        }
    }
    return pieData;

  }