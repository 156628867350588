import React from "react";
import { TextField, Select } from "../Components";
import { SubFormProps } from "../types";
import { Post, Tag, MenuItem } from "../../types/content-types";
import { Field, useFormikContext } from "formik";
import styles from "../Components.module.scss";
import Button from "../../components/Button/Button";
import TagsComponent from "../../components/Tags/Tags";
import ViewContainer from "../../components/ViewContainer/ViewContainer";
import { useEditorField } from "./useEditorField";

export default function TagsForm({
  index,
  nestedIndex = 0,
  isNested = false,
}: SubFormProps) {
  const { setFieldValue, handleChange } = useFormikContext<Post>();

  const { fieldName, blockValue, genInnerFieldName, tags, deleteTag, addTag } =
    useEditorField({
      index,
      nestedIndex,
      isNested,
    });

  const tagsTypeFieldName = fieldName("layout.auto");

  const isColumnsList: MenuItem[] = [
    { id: 1, title: "В одну колонку" },
    { id: 2, title: "В две колонки" },
  ];

  return (
    <>
      <ViewContainer>
        {blockValue && <TagsComponent contentBlock={blockValue} />}
      </ViewContainer>
      <div className={styles.selectContainer}>
        <Field
          as={Select}
          onChange={(e: any) => {
            const { value } = e.target;
            const selectVal = Number(value) === 1 ? false : true;
            setFieldValue(tagsTypeFieldName, selectVal);
          }}
          options={isColumnsList}
          name={tagsTypeFieldName}
          label="Столбцы"
          value={blockValue?.layout?.auto ? 2 : 1}
        />
      </div>

      {tags?.map((tag: Tag, tagIndex: number) => (
        <div key={tagIndex} className={styles.elementContainer}>
          <TextField
            label="Текст"
            onChange={handleChange}
            value={tag.title}
            name={genInnerFieldName(tagIndex, "title")}
          />
          <TextField
            label="URL Иконки"
            onChange={handleChange}
            value={tag.icon_url}
            name={genInnerFieldName(tagIndex, "icon_url")}
          />
          <TextField
            label="Цвет в формате `#000000` или `rgb(255,255,255)` "
            onChange={handleChange}
            style={{ background: tag.color ?? "white" }}
            name={genInnerFieldName(tagIndex, "color")}
            value={tag.color}
          />
          <div className={styles.containerRight}>
            <Button
              title="Х"
              onClick={() => {
                deleteTag(tagIndex);
              }}
            />
          </div>
        </div>
      ))}
      <div className={styles.containerCenter}>
        <Button type="button" onClick={addTag} title="Добавить пункт" />
      </div>
    </>
  );
}
