import { CLIENT_ID, makeTokenRequest } from "./AuthApiService";

const TOKENS_STORAGE_KEY = "token";

export type OAuthToken = {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  expirationDate: number;
  token_type: string;
};

export function prepareToken(token: OAuthToken) {
  let expirationDate: number = new Date().getTime();
  expirationDate += (token.expires_in - 60) * 1000;
  return { ...token, expirationDate };
}

export function saveTokensToStorage(token: OAuthToken) {
  localStorage.setItem(TOKENS_STORAGE_KEY, JSON.stringify(prepareToken(token)));
}

export function getTokensFromStorage() {
  const tokensEncoded = localStorage.getItem(TOKENS_STORAGE_KEY);
  if (!tokensEncoded) {
    return null;
  }

  try {
    return JSON.parse(tokensEncoded);
  } catch {
    removeTokens();
    return null;
  }
}

export function removeTokens(): void {
  localStorage.removeItem(TOKENS_STORAGE_KEY);
}

export function getTokenRemainingTime(token: OAuthToken): number {
  return token.expirationDate - new Date().getTime();
}

export function isTokenExpired(token: OAuthToken): boolean {
  const expirationDate = new Date(token.expirationDate);
  expirationDate.setMinutes(expirationDate.getMinutes() - 1);
  const updatedTime = expirationDate.getTime();
  return updatedTime < Date.now();
}

export const refreshToken = () =>
  new Promise<OAuthToken>(async (resolve, reject) => {
    const token: OAuthToken | null = getTokensFromStorage();
    if (!token?.access_token) {
      removeTokens();
      window.location.replace("/login");
      reject("");
      return;
    }
    const formData = new URLSearchParams();
    formData.append("refresh_token", token.refresh_token);
    formData.append("grant_type", "refresh_token");
    formData.append("client_id", CLIENT_ID);

    const resp = await makeTokenRequest(formData);

    if (!resp.ok) {
      reject(null);
    }

    const newToken = await resp.json();
    saveTokensToStorage(newToken);
    resolve(newToken);
  });
