import React, { useCallback } from "react";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import Feature from "ol/Feature";
import { RLayerVector, RFeature, RStyle, ROverlay, RPopup } from "rlayers";
import { Layer } from "../../config/interfaces";
import styles from "../../Map.module.css";
import {RectangleDiagram} from "../../elements/diagrams/RectangleDiagram";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import { setModalState } from "../../../../redux/modal";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store";
import Button from "../../../Button/Button";
import rectangle_text from "../../icons/rectangle_text.svg";
import arm_1 from "../../icons/armyane_christ/Group 2 (1).svg";
import arm_2 from "../../icons/armyane_christ/Group 2 (2).svg";
import arm_3 from "../../icons/armyane_christ/Group 2 (3).svg";
import arm_4 from "../../icons/armyane_christ/Group 2 (4).svg";
import arm_5 from "../../icons/armyane_christ/Group 2 (5).svg";
import arm_6 from "../../icons/armyane_christ/Group 2 (6).svg";
import { 
  SimpleRectangle, 
  SimpleRectangleSelectedLabel, 
  SimpleRectangleSelectedInfoWindow 
} from "../../elements/diagrams";
import { useSelector} from "react-redux";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function ReligionArmyane(layers: AtlasLayerInterface) {
  // layer1
  const layer = layers.layers[0];
  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=100&outputFormat=application%2Fjson`;

  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });
  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { value: number, church: string, descrptn: string } }[];
  };
  const fetchData = fetch(layer_url).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ features: [] } as inputDataType);
  const [current, setCurrent] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);

  // layer 3
  const layer3 = layers.layers[1];
  const layer_url3 = `https://geo.touristatlas.ru/geoserver/${layer3.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer3.workspace}%3A${layer3.layer}&maxFeatures=1000&outputFormat=application%2Fjson`;
  
  const parser3 = new GeoJSON({ featureProjection: "EPSG:4326" });

  const fetchData3 = fetch(layer_url3).then(
    (raw) => raw.json() as Promise<inputDataType>
  );
  RFeature.hitTolerance = 0;
  const [data3, setData3] = React.useState({ features: [] } as inputDataType);
  const [current3, setCurrent3] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData3.then((r) => setData3(r));
  }, []);

  const getLineColor = (f: any) => {
    let color = "#000000"
    if (layer3.color) {
      color = layer3.color;
    }
    return (<RStyle.RStroke width={2} color={color}/>); 
  }

  let diagrammColor = "#000000";
  if (layer.color) {
    diagrammColor = layer.color;
  };

  const dispatch = useDispatch<AppDispatch>();

  const handleModalOpen = (title: string, text: string) => {
    dispatch(
      setModalState({
        isVisible: true,
        title: title,
        content: <p> {text} </p>,
      })
    );
  };

  const readMore = () => {
    if (current) {
      handleModalOpen(current.get('church'), current.get('descrptn'))
    }
    
  }

  const renderDiagramms = useCallback((f: any) => {
    return (
      <RFeature 
          key={f.id}
          geometry={new Point(fromLonLat(f.geometry.coordinates))}
          properties={f.properties}
          >
          <ROverlay 
          positioning="center-center"
          >
            <div>{f.properties.church}</div>
            {/*
              <RectangleDiagram 
              value={f.properties.value} 
              width={25} height={25} 
              color={'#ffffff'} 
              background={diagrammColor}
              minValue={-1}/>
    */}
           </ROverlay>
          </RFeature>
    )
  }, [data])

  const flyTo = (e: any) => {
    setCurrent(e.target);
    const [xMin, yMin, xMax, yMax] = e.target.getGeometry().getExtent();
    const newExt = [xMin + xMin * 0.002, yMin, xMax + xMax * 0.002, yMax];
    e.map.getView().fit(newExt, {
      duration: 750,
      maxZoom: 12,
    })
  }

  const handlePointLeave = () => {
    //setCurrent(null);
    //setClicked(false);
  }

  const getIcon = (f: any) => {
    //let color = f.values_.color_hex.trim();
    setCurrent(null);
    const value = f.values_.value;
    switch (value) {
      case 1:
        return (<RStyle.RIcon src={arm_1}/>);  
        break;
      case 2:
        return (<RStyle.RIcon src={arm_2}/>);  
        break;
      case 3:
        return (<RStyle.RIcon src={arm_3}/>);  
        break;
      case 4:
        return (<RStyle.RIcon src={arm_4}/>);  
        break;
      case 5:
        return (<RStyle.RIcon src={arm_5}/>);  
        break;
      case 6:
        return (<RStyle.RIcon src={arm_6}/>);  
        break;
      default:
        return (<RStyle.RIcon src={arm_1}/>);  
    } 
  }

  const isSelected = useSelector((state: any) => state.selectFeature.isSelected);
  const selectedFeature = useSelector((state: any) => state.selectFeature.feature);

  return (
    <>
    <RLayerVector
        zIndex={2}
        format={parser}
        url={layer_url}
        visible={false}
        
        onClick={flyTo}
      >
        {isSelected ?
            <RFeature geometry={
              new Point(fromLonLat([
                selectedFeature.geometry.coordinates[0], 
                selectedFeature.geometry.coordinates[1]
                ]))}>
            <ROverlay 
            positioning="top-left">
              <SimpleRectangleSelectedInfoWindow 
                value={selectedFeature.id.split('.')[1]} 
                width={24} height={24} 
                color={'#ffffff'} 
                background={"#D65A68"}
                minValue={24}
                label={selectedFeature.properties.church}
                text={selectedFeature.properties.descrptn}/>
           </ROverlay>
          </RFeature>
          : 
          <RFeature geometry={new Point(fromLonLat([0, 0]))}>
            <ROverlay positioning="center-left"></ROverlay>
          </RFeature>
           }
        {data.features.map((f) => (
        <RFeature 
          key={f.id}
          geometry={new Point(fromLonLat(f.geometry.coordinates))}
          properties={f.properties}
          >
          <ROverlay 
          positioning="top-left">
              <SimpleRectangle 
                feature={f}
                value={Number(f.id.split('.')[1])} 
                width={24} height={24} 
                color={'#ffffff'} 
                background={"#D65A68"}
                minValue={0}
                label={f.properties.church}/>
           </ROverlay>
          </RFeature>
        ))}
      </RLayerVector>
    {/*
      <RLayerVector
        zIndex={2}
        onPointerEnter={useCallback((e: any) => setCurrent(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current === e.target && setCurrent(null),
          [current]
        )}
        onClick={flyTo}
      >
        {data.features.map((f) => (
          renderDiagramms(f)
        ))}
      </RLayerVector>
        */}
      <RLayerVector
        zIndex={1}
        format={parser3}
        url={layer_url3}
      >
        <RStyle.RStyle
          render={useCallback(
            (f: any) => (getLineColor(f)),
            [data3]
          )}
        />
      </RLayerVector>

      { layer.label ? 
      <RLayerVector zIndex={3}>
        {current ? (
          <div>
            <RFeature geometry={current.getGeometry()}>
            <RPopup trigger={"click"} className={styles.customPopup} positioning='top-left'>
                <div className={styles.framePopup}>
                <div className={styles.titlePopup}>{current.get('church')}</div>
                <div className={styles.titleContentText}>{current.get('descrptn').split('.')[0] + "..."}</div>
                <div className={styles.buttonReadMore}>
                <Button title="Читать далее" onClick={readMore}/>
                </div>
            </div>
            </RPopup>
            </RFeature>
          </div>
        ) : null}
      </RLayerVector> : null }
    </>
  );
}
