import React from "react";
import { ContentBlock } from "../../../types/content-types";
import DonutChart from "./DonutChart";
import ContentBlockBase from "../../ContentBlockBase";

export default function DonutChartBlock({
  contentBlock,
}: {
  contentBlock: ContentBlock;
}) {
  return (
    <ContentBlockBase contentBlock={contentBlock}>
      {contentBlock.donut_chart ? (
        <DonutChart {...contentBlock.donut_chart} />
      ) : (
        <></>
      )}
    </ContentBlockBase>
  );
}
