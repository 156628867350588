import React from "react";
import { SubFormProps } from "../types";
import { TextField } from "../Components";

import ViewContainer from "../../components/ViewContainer/ViewContainer";
import ImageBlock from "../../components/ImageBlock/ImageBlock";
import { useEditorField } from "./useEditorField";

export default function URLForm({
  index,
  nestedIndex = 0,
  isNested = false,
}: SubFormProps) {
  const { fieldName, fieldValue, handleChange, blockValue } = useEditorField({
    index,
    nestedIndex,
    isNested,
  });

  return (
    <>
      <ViewContainer>
        {blockValue && <ImageBlock contentBlock={blockValue} />}
      </ViewContainer>
      <TextField
        label="URL Картинки (рекомендуется не меньше 1000px в ширину)"
        onChange={handleChange}
        value={fieldValue("url")}
        name={fieldName("url")}
      />
    </>
  );
}
