import React from "react";
import { ContentBlock } from "../../../types/content-types";
import BarChart from "./BarChart";
import ContentBlockBase from "../../ContentBlockBase";

export default function BarChartBlock({
  contentBlock,
}: {
  contentBlock: ContentBlock;
}) {
  return (
    <ContentBlockBase contentBlock={contentBlock}>
      {contentBlock.bar_chart ? (
        <BarChart {...contentBlock.bar_chart} />
      ) : (
        <></>
      )}
    </ContentBlockBase>
  );
}
