import React, { useState } from "react";
import { Block, ContentBlock } from "../../types/content-types";

import Button from "../Button/Button";

interface TabsProps {
  index?: number | string;
  contentBlock: Block;
}

const Tabs = ({ contentBlock, index }: TabsProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabs = contentBlock.tabs ?? [];

  return (
    <div key={index}>
      {tabs.map((tab: ContentBlock, tabIndex) => (
        <Button
          onClick={() => {
            setActiveTab(tabIndex);
          }}
          status={tabIndex === activeTab ? "disabled" : "enabled"}
          key={`button_${tabIndex}`}
          title={tab.header ?? "Вкладка"}
        />
      ))}
    </div>
  );
};

export default Tabs;
