import BaseStyles from "../components/common.module.scss";
import WindowAuth, { AuthStep } from "../components/Auth/WindowAuth";

export default function LoginPage({ step }: { step: AuthStep }) {
  return (
    <>
      <div className={BaseStyles.splashScreen} />
      <WindowAuth preStep={step} />
    </>
  );
}
