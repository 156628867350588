import Main from "../components/Map/MapOverLayer";
import BaseStyles from "../components/common.module.scss";
import WelcomeWindow from "../components/WelcomeWindow/WelcomeWindow";
import Navbar from "../components/Navbar/Navbar";

export default function WelcomePage() {
  return (
    <>
      <div className={BaseStyles.splashScreen} />
      <Navbar activePage={"Путеводитель"} />
      <Main>
        <WelcomeWindow />
      </Main>
    </>
  );
}
