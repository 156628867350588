import GeoJSON from "ol/format/GeoJSON";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RFeature, RLayerVector, ROverlay } from "rlayers";
import { Layer } from "../../config/interfaces";
import { Geometry } from "ol/geom";
import Feature from 'ol/Feature';
import { FeatureLike } from 'ol/Feature';
import { select, setSelectedFeature } from "../../../../redux/map/featureReducer";
import { CustomInfoWindow } from "./CustomInfowindowCreator";
import createFeatureStyle from './StyleFeaturePoint';

interface MapPointsCreatorInterface {
  layer: Layer;
  order: number;
}

export function MapPointsCreator({ layer, order }: MapPointsCreatorInterface) {

  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=1000&outputFormat=application%2Fjson`;
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });

  const [data, setData] = useState({ features: [] });
  const [current, setCurrent] = useState<Feature<Geometry> | null>(null);
  
  const dispatch = useDispatch();
  const isSelected = useSelector((state: any) => state.selectFeature.isSelected);
  const selectedFeature = useSelector((state: any) => state.selectFeature.feature);

  useEffect(() => {
    fetch(layer_url)
      .then((response) => response.json())
      .then((jsonData) => setData(jsonData))
      .catch((error) => console.error("Error fetching layer data:", error));
  }, [layer_url]);

  const handleSetCurrent = (e: any) => {
    setCurrent(e);

    dispatch(select());
    dispatch(setSelectedFeature(e));
  }

  const handleDeselectCurrent = (e: any) => {
    setCurrent(e);
  }

  const handlePointerEnter = useCallback((e: any) => {
    // Меняем курсор на 'pointer'
    const mapTarget = e.map.getTargetElement();
    mapTarget.style.cursor = 'pointer';

    handleSetCurrent(e.target);
  }, [])

  const handlePointerLeave = useCallback((e: any) => {
    // Возвращаем курсор обратно
    const mapTarget = e.map.getTargetElement();
    mapTarget.style.cursor = '';

    if (current === e.target) {
      handleDeselectCurrent(null);
    }
  }, [current])

  const handleFeatureClick = useCallback((e: any) => {
    const [xMin, yMin, xMax, yMax] = e.target.getGeometry().getExtent();
    const newExt = [xMin + xMin * 0.01, yMin, xMax + xMax * 0.01, yMax];
    e.map.getView().fit(newExt, {
      duration: 750,
      maxZoom: 9,
    });
  }, []);


  let fillColor = '#000000';
  const outlineColor = '#ffffff';

  return (
    <>
    <RLayerVector
    zIndex={order}
    format={parser}
    url={layer_url}
    visible={layer.visible}
    style={useCallback(
      (feature: FeatureLike) => {
        const isHovered = feature === current; // Проверяем, является ли текущая фича наведеной
        const fillColor = feature.getProperties()['color_hex'];
        return createFeatureStyle({ 
          feature, 
          isHovered, 
          fillColor, 
          outlineColor 
        });
      },
      [fillColor, outlineColor, current]
    )}
    onPointerEnter={handlePointerEnter}
    onPointerLeave={handlePointerLeave}
    onClick={handleFeatureClick}
    />
        {isSelected && layer.marker && layer.infowindow ? 
        <RLayerVector zIndex={order}>
                <RFeature geometry={selectedFeature.getGeometry()}>
                <ROverlay
                    positioning='top-left'
                >
                    <CustomInfoWindow 
                    infowindow={layer.infowindow}
                    properties={selectedFeature.getProperties()}/>
                </ROverlay>
                </RFeature>
        </RLayerVector> : null }
    </>
    );
    }