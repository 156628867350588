import React from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";

export type ButtonStatus = "enabled" | "disabled" | "loading" | "done";
export type ButtonSize = "medium" | "large";

export type ButtonProps = {
  /**
   * Состояние кнопки (влияет на внешний вид и возможность нажать ее).
   * По умолчанию: "enabled"
   */
  status?: ButtonStatus;
  /** Размер кнопки, по умолчанию medium */
  size?: ButtonSize;
  /** Текст кнопки */
  title: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: "button" | "submit";
};

const Button: React.FC<ButtonProps & React.ComponentProps<"button">> = ({
  title,
  status = "enabled",
  size = "medium",
  onClick,
  type,
  ...restProps
}) => {
  return (
    <button
      type={type ?? "button"}
      className={classNames(styles.button, styles[size], {
        [styles.disabled]: status === "disabled",
      })}
      disabled={status !== "enabled"}
      onClick={onClick}
      {...restProps}
    >
      {title}
    </button>
  );
};

export default Button;
