import React, { useCallback, useEffect, useRef, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { useSelector, useDispatch } from "react-redux";
import {
  addAbstractLayerToWorkspace,
  removeAbstractLayerFromWorkSpace,
  reorderAbstractLayersInWorkspace,
  reorderLayers,
  syncAbstractLayersFromWorkSpaceToMapLayers,
} from "../../redux/map";
import { RootState, AppDispatch } from "../../store";
import LayerElement from "../LayerElement/LayerElement";
import styles from "./Constructor.module.scss";
import { AbstractLayer } from "../../pages/ConstructorPage";
import ListElement from "../LayerElement/ListElement";
import LinkItem from "../listMenu/LinkItem";
import classNames from "classnames";

type List = { [key: string]: AbstractLayer[] };

function restructureAbstractLayers(abstractLayers: AbstractLayer[]) {
  const result: List = {};
  abstractLayers.forEach((item) => {
    if (!result[item.chapter_title]) {
      result[item.chapter_title] = [];
    }
    result[item.chapter_title].push(item);
  });
  return result;
}

export default function Constructor() {
  const defaultTopHeight = (window.innerHeight - 176 - 6) * 0.4;
  const [topHeight, setTopHeight] = useState(defaultTopHeight);
  const [resizerPressed, setResizerPressed] = useState<boolean>(false);

  const topRef = useRef<HTMLDivElement>(null);
  const workspaceRef = useRef<HTMLDivElement>(null);

  const mapLayers = useSelector((state: RootState) => state.map).map_config
    ?.layers;

  const { abstractLayers } = useSelector((state: RootState) => state.map);
  const { abstractLayersWorkspace } = useSelector(
    (state: RootState) => state.map
  );

  const layersListByChapters = restructureAbstractLayers(abstractLayers);

  const dispatch = useDispatch<AppDispatch>();

  const onSortEnd = useCallback(
    (oldIndex: number, newIndex: number) => {
      if (isNaN(newIndex)) return;
      dispatch(
        reorderAbstractLayersInWorkspace({
          startIndex: oldIndex,
          endIndex: newIndex,
        })
      );

      dispatch(reorderLayers({ startIndex: oldIndex, endIndex: newIndex }));
    },
    [dispatch]
  );

  const workspaceTitles = abstractLayersWorkspace.map((obj) => obj.post_title);

  const handleToggleAbstractLayerToWorkspace = (
    isItemInWorkspace: boolean,
    layer: AbstractLayer
  ) => {
    const index = abstractLayersWorkspace.indexOf(layer);
    isItemInWorkspace
      ? dispatch(removeAbstractLayerFromWorkSpace(index))
      : dispatch(addAbstractLayerToWorkspace(layer));
  };

  const containerHeight = window.innerHeight - 176 - 6;
  const maxTopHeight = containerHeight - 56;
  const workspaceHeight = containerHeight - topHeight;

  const resizeLayersList = () => {
    if (topHeight < defaultTopHeight) {
      setTopHeight(defaultTopHeight);
    } else if (topHeight < maxTopHeight) {
      setTopHeight(maxTopHeight);
    }
  };

  const resizeWorkspace = () => {
    if (topHeight > defaultTopHeight) {
      setTopHeight(defaultTopHeight);
    } else if (topHeight > 56) {
      setTopHeight(56);
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const startY = e.clientY;
    const startHeight = topHeight;

    const onMouseMove = (e: MouseEvent) => {
      const newHeight = startHeight + (e.clientY - startY);
      if (newHeight > 56 && containerHeight - newHeight > 56) {
        setTopHeight(newHeight);
      } else if (containerHeight - newHeight > 56) {
        if (topRef.current) {
          topRef.current.scrollTop = 0;
        }
        setTopHeight(56);
      } else if (newHeight > 56) {
        if (workspaceRef.current) {
          workspaceRef.current.scrollTop = 0;
        }
        if (topHeight !== containerHeight - 56)
          setTopHeight(containerHeight - 56);
      }
    };

    const onMouseUp = () => {
      setResizerPressed(false);
      document.body.style.cursor = "auto";
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    setResizerPressed(true);
    document.body.style.cursor = "row-resize";
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  useEffect(() => {
    if (abstractLayersWorkspace.length && mapLayers?.length === 0) {
      dispatch(syncAbstractLayersFromWorkSpaceToMapLayers());
    }
  }, [
    abstractLayersWorkspace,
    abstractLayersWorkspace.length,
    dispatch,
    mapLayers?.length,
  ]);

  return (
    <div>
      <div
        ref={topRef}
        className={classNames(styles.layersListContainer, {
          [styles.disableHover]: resizerPressed,
          [styles.disableScroll]: topHeight === 56,
        })}
        style={{
          height: topHeight,
          transitionDuration: resizerPressed ? "0ms" : "500ms",
        }}
      >
        <div className={styles.titleContainer}>
          <div className={styles.title}>База слоев</div>
          <div
            className={classNames(styles.titleDetailsIconDown, {
              [styles.disabled]: topHeight === maxTopHeight,
            })}
            onClick={resizeLayersList}
          />
        </div>
        {Object.keys(layersListByChapters).map((chapter, index) => (
          <ListElement
            key={index}
            title={chapter}
            Content={
              <>
                {layersListByChapters[chapter].map((layer, index) => {
                  const isItemInWorkspace = workspaceTitles.includes(
                    layer.post_title
                  );

                  return (
                    <LinkItem
                      key={index + layer.post_title}
                      title={layer.post_title}
                      handleClick={() => {
                        handleToggleAbstractLayerToWorkspace(
                          isItemInWorkspace,
                          layer
                        );
                      }}
                      checked={isItemInWorkspace}
                      style={{ marginLeft: 18 }}
                    />
                  );
                })}
              </>
            }
          />
        ))}
      </div>
      <div
        className={classNames(styles.resizer, {
          [styles.active]: resizerPressed,
        })}
        onMouseDown={handleMouseDown}
      />
      <div
        ref={workspaceRef}
        className={classNames(styles.workspaceContainer, {
          [styles.disableHover]: resizerPressed,
          // [styles.disableScroll]: topHeight === 56,
          [styles.disableScroll]: topHeight === maxTopHeight,
        })}
        style={{
          height: workspaceHeight,
          transitionDuration: resizerPressed ? "0ms" : "500ms",
        }}
      >
        <div className={styles.titleContainer}>
          <div className={styles.title}>Рабочая область</div>
          <div
            className={classNames(styles.titleDetailsIconUp, {
              [styles.disabled]: topHeight === 56,
            })}
            onClick={resizeWorkspace}
          />
        </div>
        <SortableList onSortEnd={onSortEnd} draggedItemClassName="dragged">
          {abstractLayersWorkspace?.map((layer, index) => (
            <SortableItem key={layer.post_title}>
              <div style={{ zIndex: 2 }}>
                <LayerElement layer={layer} index={index} />
              </div>
            </SortableItem>
          ))}
        </SortableList>
      </div>
    </div>
  );
}
