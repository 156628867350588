import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { useEffect } from "react";
import { getMenuItemsThunk } from "../../redux/menu";

export function useMenu() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getMenuItemsThunk());
  }, [dispatch]);
}
