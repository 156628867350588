import React, { useState } from "react";
import styles from "./Auth.module.scss";
import { ModuleProps } from "./WindowAuth";
import Button from "../Button/Button";
import { TextField } from "../Input/TextField/TextField";
import useEnterKeyListener from "../../hooks/useEnterKeyListener";
import { sendPassRestoreEmail } from "../../common/ApiService";

export default function PassRecoveryModule({
  setStep,
  credentials,
  setCredentials,
}: ModuleProps) {
  const [touched, setTouched] = useState(false);
  const [isEnterPressed, setIsEnterPressed] = useState(false);

  const emailNotValid = !credentials.email.match(
    /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
  );

  const handlePassRecovery = async () => {
    sendPassRestoreEmail(credentials.email).then(() => {
      setStep("successrecovery");
    });
  };

  const handleKeyDown = () => {
    setIsEnterPressed(true);
  };

  const handleKeyUp = () => {
    setIsEnterPressed(false);
    handlePassRecovery();
  };

  useEnterKeyListener(handleKeyDown, handleKeyUp);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.authModule}>
        <div className={styles.title}>Изменение пароля</div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldTitle}>Электронная почта </div>
          <TextField
            type="text"
            placeholder="user@mail.com"
            value={credentials.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCredentials((prev) => ({
                ...prev,
                email: e.target.value,
                login: e.target.value,
              }));
            }}
            onBlur={() => {
              if (!touched) {
                setTouched(true);
              }
            }}
            isValid={!emailNotValid || !touched}
            validationMessage="Неправильный формат почты"
          />
        </div>
        <Button
          title="Продолжить"
          size="large"
          onClick={handlePassRecovery}
          style={{
            width: "100%",
            backgroundColor: isEnterPressed ? "#0a1f70" : "",
          }}
        />
        <div className={styles.flexCenterContainer}>
          <div
            className={styles.footerLink}
            onClick={() => {
              setStep("mailcheck");
            }}
          >
            Вернуться на страницу авторизации
          </div>
        </div>
      </div>
    </div>
  );
}
