export const hexToRgba = (hex: string, transparency: number) => {
    // Удаляем символ "#" если он есть
    hex = hex.replace(/^#/, '');

    // Преобразуем HEX в RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Преобразуем прозрачность из процента в число от 0 до 1
    let alpha = transparency / 100;

    // Собираем строку в формате rgba
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}