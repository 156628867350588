import React, { useState } from "react";
import styles from "./Auth.module.scss";
import { ModuleProps } from "./WindowAuth";
import Button from "../Button/Button";
import { TextField } from "../Input/TextField/TextField";
import { IUserSignUpData } from "../../types/user";
import { userSignUp } from "../../auth/AuthApiService";
import CheckElement from "../CheckElement/CheckElement";
import useEnterKeyListener from "../../hooks/useEnterKeyListener";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { getUserInfoThunk } from "../../redux/auth/auth.reducer";

export default function RegModule({
  setStep,
  credentials,
  setCredentials,
}: ModuleProps) {
  const [touched, setTouched] = useState(false);
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const handleRegistration = async () => {
    !touched && setTouched(true);
    if (passwordValidation.every((rule) => rule.isValid)) {
      const signUpData: IUserSignUpData = {
        login: credentials.login,
        email: credentials.email,
        password: credentials.password,
      };
      try {
        await userSignUp(signUpData).then(() => {
          dispatch(getUserInfoThunk());
        });
        setStep("confirmation");
      } catch (error) {
        console.error("Registration failed:", error);
      }
    }
  };

  const passwordValidation = [
    {
      validationMessage: "8-32 символа",
      isValid:
        !(credentials.password.length < 8) &&
        !(credentials.password.length > 32),
    },
    {
      validationMessage: "Латинские буквы и цифры",
      isValid:
        /[A-Za-z]/.test(credentials.password) &&
        /[0-9]/.test(credentials.password),
    },
    {
      validationMessage: "Строчные и заглавные буквы",
      isValid:
        /[A-Z]/.test(credentials.password) &&
        /[a-z]/.test(credentials.password),
    },
    {
      validationMessage: "Без пробелов",
      isValid: !/\s/.test(credentials.password),
    },
  ];

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!touched) {
      setTouched(true);
    }
    const value = e.target.value;
    if (!/[А-Яа-я]/g.test(value)) {
      setCredentials((prev) => ({
        ...prev,
        password: value,
      }));
    }
  };

  const handleKeyDown = () => {
    setIsEnterPressed(true);
  };

  const handleKeyUp = () => {
    setIsEnterPressed(false);
    handleRegistration();
  };

  useEnterKeyListener(handleKeyDown, handleKeyUp);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.authModule}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Регистрация</div>
          <button
            className={styles.back}
            onClick={() => {
              setStep("mailcheck");
            }}
          >
            Назад
          </button>
        </div>

        <div className={styles.fieldContainer}>
          <div className={styles.fieldTitle}>Электронная почта</div>
          <TextField type="text" value={credentials.email} disabled />
          <div className={styles.fieldTitle}>Придумайте пароль</div>
          <TextField
            type="password"
            placeholder="Пароль"
            value={credentials.password}
            onChange={handlePasswordChange}
            onBlur={() => {
              if (!touched) {
                setTouched(true);
              }
            }}
            isValid={
              passwordValidation.every((rule) => rule.isValid) || !touched
            }
          />
          <div>
            {passwordValidation.map((item) => (
              <CheckElement
                key={item.validationMessage}
                title={item.validationMessage}
                status={
                  !touched ? "disabled" : item.isValid ? "success" : "wrong"
                }
              />
            ))}
          </div>
        </div>
        <Button
          title="Продолжить"
          size="large"
          onClick={handleRegistration}
          style={{
            width: "100%",
            backgroundColor: isEnterPressed ? "#0a1f70" : "",
          }}
        />
      </div>
    </div>
  );
}
