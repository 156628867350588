import { Layer } from "../../config/interfaces";
import { MapPolygonCreator, MapLineCreator, 
  MapCartogramCreator, MapPieCreator, MapPointsCreator } from "./index";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function MapCreator(layers: AtlasLayerInterface) {
  const all_layers = layers.layers;
  
  const getLayerComponent = (layer: Layer) => {

    let order: number = copiedArray.findIndex((l: Layer) => l.layer === layer.layer) + 1; //fix it!

    console.log(layer.layer, order);
    

    switch (layer.geometry_type) {
        case "points":
          return (<MapPointsCreator layer={layer} order={order}/>)
        case "point":
          return (<MapPointsCreator layer={layer} order={order}/>)
        case "polygon":
          return (<MapPolygonCreator layer={layer} order={order}/>)
        case "line":
          return (<MapLineCreator layer={layer} order={order}/>)
        case "cartogram":
          return (<MapCartogramCreator layer={layer} order={order}/>)
        case "pie":
          return (<MapPieCreator layer={layer}/>)
        default:
          return null;
        }
    }

  const copiedArray = all_layers.slice(); // Копируем оригинальный массив
  copiedArray.reverse();

  return (
    <>
        {copiedArray.map((layer) => (
          getLayerComponent(layer)
        ))}
    </>
  );
}
