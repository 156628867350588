import BaseStyles from "../components/common.module.scss";
import AboutWindow from "../components/AboutWindow/AboutWindow";
import Navbar from "../components/Navbar/Navbar";
import PageContainer from "../components/PageContainer/PageContainer";

export default function NewsPage() {
  return (
    <>
      <div className={BaseStyles.splashScreenOpacity} />
      <Navbar activePage={"О нас"} />
      <PageContainer>
        <AboutWindow />
      </PageContainer>
    </>
  );
}
