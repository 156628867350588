import React from "react";
import { Select, TextField } from "../Components";
import { SubFormProps } from "../types";
import { Tag } from "../../types/content-types";
import styles from "../Components.module.scss";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useEditorField } from "./useEditorField";
import ViewContainer from "../../components/ViewContainer/ViewContainer";
import TagsHorizontal from "../../components/TagsHorizontal/TagsHorizontal";

export default function TagsHorizontalForm({
  index,
  nestedIndex = 0,
  isNested = false,
}: SubFormProps) {
  const { mapsList } = useSelector((state: RootState) => state.map);

  const {
    genInnerFieldName,
    tags,
    deleteTag,
    addTag,
    blockValue,
    handleChange,
    setFieldValue,
  } = useEditorField({
    index,
    nestedIndex,
    isNested,
  });

  return (
    <>
      <ViewContainer>
        {blockValue && <TagsHorizontal contentBlock={blockValue} />}
      </ViewContainer>
      {tags?.map((tag: Tag, tagIndex: number) => (
        <div key={tagIndex} className={styles.elementContainer}>
          <TextField
            label="Текст"
            onChange={handleChange}
            value={tag.title}
            name={genInnerFieldName(tagIndex, "title")}
          />
          <Select
            onChange={(e) => {
              const { value } = e.target;
              const properVal = Number(value) === 0 ? undefined : Number(value);
              setFieldValue(genInnerFieldName(tagIndex, "map_id"), properVal);
            }}
            options={mapsList}
            name={genInnerFieldName(tagIndex, "map_id")}
            label="Выбрать слой для пункта легенды"
            value={tag.map_id}
          />
          <div className={styles.containerRight}>
            <Button
              title="Х"
              onClick={() => {
                deleteTag(tagIndex);
              }}
            />
          </div>
        </div>
      ))}
      <div className={styles.containerCenter}>
        <Button type="button" onClick={addTag} title="Добавить пункт" />
      </div>
    </>
  );
}
