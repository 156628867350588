import GeoJSON from "ol/format/GeoJSON";
import Feature from "ol/Feature";
import { Geometry } from "ol/geom";
import React, { useCallback, useMemo } from "react";
import { RFeature, RLayerVector, RStyle, ROverlay } from "rlayers";
import { Layer, Marker } from "../../config/interfaces";
import Pie from "../../../Charts/PieChart/PieChart";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import { getPieData } from "../../utils/get_pie_data";

interface MapPolygonCreatorInterface {
  layer: Layer;
}

export function MapPieCreator(layer_prop: MapPolygonCreatorInterface) {

  const layer = layer_prop.layer;

  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });
  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { name: string, hotels_sum: number, 
        stars_0: number, 
        stars_1: number, 
        stars_2: number, 
        stars_3: number,
        stars_4: number, 
        stars_5: number,} }[];
  };
  const fetchData = useMemo(() => {
    return fetch(layer_url).then((raw) => raw.json() as Promise<inputDataType>);
  }, [layer_url]);

  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ features: [] } as inputDataType);

  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);

  const sort_data: any[] = [];
  data.features.map((f) => (sort_data.push(f)));
  sort_data.sort((a, b) => Number(a.properties.hotels_sum) - Number(b.properties.hotels_sum));

  const getMarker = (f: any, marker: Marker) => {
    const pieData = getPieData(f.properties, marker);
    
    return (
        <Pie values={pieData.sectors} 
            radius={Number(pieData.size) * Number(pieData.size_multiplication)}
            tooltip={pieData.tooltip}
            tooltipDescription={`${pieData.tooltipDescription} ${pieData.tooltipDescriptionProp}`}/>
    )
  }

  return (
    <>
      <RLayerVector
        zIndex={2}
        visible={false}
      >
        {data.features.map((f) => (
            layer.marker ? (
                <RFeature 
                    key={f.id}
                    geometry={new Point(fromLonLat(f.geometry.coordinates))}
                    properties={f.properties}
                    >
                    <ROverlay 
                    positioning="center-center">
                        {getMarker(f, layer.marker)}
                    </ROverlay>
                    </RFeature>
            ) : null
        ))}

      </RLayerVector>
    </>
  );
}
