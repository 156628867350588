import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import React, { useCallback, useMemo } from "react";
import { RFeature, RLayerVector, RPopup, RStyle } from "rlayers";
import PopUp from "../../../PopUp/PopUp";
import { Layer } from "../../config/interfaces";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function YufuRastitelnost(layers: AtlasLayerInterface) {

  type inputDataType = {
        records: { fields: { name: string, type: string } }[];
      };

  // layer 3
  const layer = layers.layers[0];
  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });

  const fetchData = useMemo(() => {
    return fetch(layer_url).then((raw) => raw.json() as Promise<inputDataType>);
  }, [layer_url]);

  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ records: [] } as inputDataType);
  const [current, setCurrent] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);

  const getLineColor = (f: any) => {
    let color = '#008720';
    return (
        <>
        <RStyle.RFill color={color}/>
        <RStyle.RStroke width={1} color={'#f5f5f5'}/>
        </>
        ); 
  }

  //let classified_prop = "area";

  return (
    <>

      <RLayerVector
        zIndex={1}
        format={parser}
        url={layer_url}
        onPointerEnter={useCallback((e: any) => setCurrent(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current === e.target && setCurrent(null),
          [current]
        )}
      >
        <RStyle.RStyle
          render={useCallback(
            (f: any) => (getLineColor(f)),
            []
          )}
        />
      </RLayerVector>

      { layer.label ? 
      <RLayerVector zIndex={5}>
        {current ? (
          (<div>
            <RFeature geometry={current.getGeometry()}>
              <RPopup trigger="hover"
                positioning="center-left">
                  <PopUp popupData={{title: "Площадь, га", 
                  subTitle: current.get('area'), colorSubTitle: true, smallPopup: true}}/>
              </RPopup>
            </RFeature>
          </div>)
        ) : null}
      </RLayerVector> : null }
    </>
  );
}
