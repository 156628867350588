import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry, Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RFeature, RLayerVector, ROverlay, RPopup, RStyle } from "rlayers";
import PopUp from "../../../PopUp/PopUp";
import { Layer } from "../../config/interfaces";
import {
  SimpleRectangle,
  SimpleRectangleSelectedLabelnoValue
} from "../../elements/diagrams";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function YufuRamsar(layers: AtlasLayerInterface) {
  // layer1
  
  const layer = layers.layers[0];
  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=7000&outputFormat=application%2Fjson`;

  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });
  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { 
        name: string, 
        type: string, 
        address: string,
        town: string,
        color_hex: string } }[];
  };
  const fetchData = useMemo(() => {
    return fetch(layer_url).then((raw) => raw.json() as Promise<inputDataType>);
  }, [layer_url]);
  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ features: [] } as inputDataType);

  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);
  RFeature.hitTolerance = 0;

  const isSelected = useSelector((state: any) => state.selectFeature.isSelected);
  const selectedFeature = useSelector((state: any) => state.selectFeature.feature);


  // layer2
  
  const layer2 = layers.layers[1];
  const layer_url2 = `https://geo.touristatlas.ru/geoserver/${layer2.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer2.workspace}%3A${layer2.layer}&maxFeatures=7000&outputFormat=application%2Fjson`;

  const parser2 = new GeoJSON({ featureProjection: "EPSG:4326" });

  const fetchData2 = useMemo(() => {
    return fetch(layer_url2).then((raw) => raw.json() as Promise<inputDataType>);
  }, [layer_url2]);
  RFeature.hitTolerance = 0;
  const [data2, setData2] = React.useState({ features: [] } as inputDataType);
  const [current, setCurrent] = React.useState(
    null as Feature<Geometry> | null
  );

  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);
  RFeature.hitTolerance = 0;

  const getLineColor = (f: any) => {
    let color = '#99d606';
    return (
        <>
        <RStyle.RFill color={color}/>
        <RStyle.RStroke width={1} color={'#f5f5f5'}/>
        </>
        ); 
  }

  return (
    <>
    <RLayerVector
        zIndex={2}
        format={parser}
        url={layer_url}
        visible={false}
      >
        {isSelected ?
            <RFeature geometry={
              new Point(fromLonLat([
                selectedFeature.geometry.coordinates[0], 
                selectedFeature.geometry.coordinates[1]
                ]))}>
            <ROverlay 
            positioning="top-left">
              <SimpleRectangleSelectedLabelnoValue 
                width={20} height={20} 
                color={'#ffffff'} 
                background={'#99d606'}
                minValue={0}
                label={selectedFeature.properties.name}/>
           </ROverlay>
          </RFeature>
          : 
          <RFeature geometry={new Point(fromLonLat([0, 0]))}>
            <ROverlay positioning="center-left"></ROverlay>
          </RFeature>
           }
        {data.features.map((f) => (
        <RFeature 
          key={f.id}
          geometry={new Point(fromLonLat(f.geometry.coordinates))}
          properties={f.properties}
          >
          <ROverlay 
          positioning="top-left">
              <SimpleRectangle 
                feature={f}
                value={0} 
                width={16} height={16} 
                color={'#ffffff'} 
                background={'#99d606'}
                minValue={0}
                label={f.properties.name}/>
           </ROverlay>
          </RFeature>
        ))}
      </RLayerVector>

      <RLayerVector
        zIndex={1}
        format={parser2}
        url={layer_url2}
        onPointerEnter={useCallback((e: any) => setCurrent(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current === e.target && setCurrent(null),
          [current]
        )}
      >
        <RStyle.RStyle
          render={useCallback(
            (f: any) => (getLineColor(f)),
            []
          )}
        />
      </RLayerVector>

      { layer.label ? 
      <RLayerVector zIndex={5}>
        {current ? (
          (<div>
            <RFeature geometry={current.getGeometry()}>
              <RPopup trigger="hover"
                positioning="center-left">
                  <PopUp popupData={{title: "Площадь, га", 
                  subTitle: `${String(Number(current.get('area_hecta')) / 1000)} 000`, colorSubTitle: true, smallPopup: true}}/>
              </RPopup>
            </RFeature>
          </div>)
        ) : null}
      </RLayerVector> : null }
    </>
  );
}
